import Vue from 'vue'
import Vuex from 'vuex'
import i18n from './i18n'
import {ALL_LOCALES} from './i18n'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    locale: process.env.VUE_APP_I18N_DEFAULT_LOCALE,
    isActiveMobMenu: false,
    isPageNotFound: false
  },
  mutations: {
    setLocale(state, locale) {
      if (ALL_LOCALES.indexOf(locale) !== -1) {
        state.locale = locale
        i18n.locale = locale
      } else {
        throw new Error('Invalid value!')
      }
    },
    setMobMenuStatus(state, status) {
      state.isActiveMobMenu = status
    },
    setPageNotFound(state, status) {
      state.isPageNotFound = status
    }
  },
  getters: {
    getMobMenuStatus(state) {
      return state.isActiveMobMenu
    }
  },
  subscribe: ((mutation, state) => {
    console.log(mutation.type)
    console.log(mutation.payload)
  })
})
