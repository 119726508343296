<template>
  <div class="mcf-container">
    <div class="mcf-content-wrapper">
      <spinner :loading="!!loaderCount"/>

      <div v-if="object != null && !object.completed && notificationHeader" ref="notificationEdit"
           class="mcf-notification mcf-notification-top"
           v-show="!loaderCount">
        <i class="mcf-icon mcf-icon-exclamation-mark"></i>
        {{ notificationHeader }}
      </div>

      <object-renderer v-if="Boolean(object)" :object="object" :render-mode="renderMode"
                       v-on:is-rendered-object="hideSpinner"/>
    </div>
  </div>
</template>

<script>
import ObjectRenderer from '../../components/patientData/objects/ObjectRenderer'
import Object from '../../models/patientData/Object'
import RenderMode from '../../components/patientData/RenderMode'
import {mixinLoader} from '../../mixins/mixin-loader'
import router from '@/router'

export default {
  name: 'ObjectEdit',
  components: {
    'object-renderer': ObjectRenderer
  },
  data() {
    return {
      object: null,
      renderMode: RenderMode.MODIFY_ITEM,
      activeDisplaySetting: null,
    }
  },
  computed: {
    isEmbedUrl() {
      return this.$route.query && this.$route.query.displayType === 'embed'
    },
    notificationHeader() {
      if (this.activeDisplaySetting && this.activeDisplaySetting.notificationHeader) {
        if (!this.activeDisplaySetting.notificationHeader.trim().length) {
          return "";
        }
        return this.activeDisplaySetting.notificationHeader
      }
      return this.$t("object.display.edit.notification")
    },
  },
  mixins: [mixinLoader],
  async created() {
    this.showSpinner()
    if (this.$route.params != null && this.$route.params.id != null) {
      let obj = new Object({id: this.$route.params.id})
      await obj.fetch()
        .catch((data) => {
          switch (data.error.response.status) {
            case 404:
              this.$store.commit('setPageNotFound', true)
              return
          }
        })
      if(obj.isNested) {
        this.$store.commit('setPageNotFound', true)
      }
      this.object = obj
      if (obj.completed) {
        await this.$router.push({ path: this.objectShowLink() })
      }

      this.objectDisplaySettings = await obj.getDisplaySettings()
      if (this.objectDisplaySettings.length > 0) {
        this.activeDisplaySetting = this.objectDisplaySettings.find(it => it.displayPage === "EDIT");
      }
    } else {
      this.$store.commit('setPageNotFound', true)
    }
  },
  methods: {
    showSpinner() {
      this.$emit('loading-increment')
    },
    hideSpinner() {
      this.$emit('loading-decrement')
    },
    objectShowLink() {
      if (this.isEmbedUrl) {
        return '/object/show/' + this.object.id + '?displayType=embed'
      } else {
        return '/object/show/' + this.object.id
      }
    },
  },
}
</script>
