import { Model } from 'vue-mc'
import { Ajax } from '../../util/ajaxLoader'
import store from '../../vuex'

export default class AttributeCategory extends Model {

  defaults () {
    return {
      id: null,
      code: '',
    }
  }

  mutations () {
    return {
      id: (id) => Number(id) || null,
      name: String,
    }
  }

  async fetch (options = {}) {
    let defaultHeaders = await Ajax.getDefaultHeaders(store)
    options.headers = { ...options.headers, ...defaultHeaders }
    return super.fetch(options)
  }

  routes () {
    return {
      fetch: `${process.env.VUE_APP_HEALTHBANK_HOST}api/v0/attributeCategory/{id}`,
    }
  }
}
