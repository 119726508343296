<template>
  <div>
    <!--remove object-->
    <modal-direction
      v-if="deleteObjectModalOpen"
      v-model="deleteObjectModalOpen"
      :parentExclude="'parentExclude1'"
      @modal-hide="hideModal"
    >
      <template v-slot:header>
        <div class="mcf-modal-header-icon mcf-modal-header-decline"><em class="mcf-icon mcf-icon-trash-fill"></em></div>
      </template>
      <template v-slot:body>
        <h1 class="mcf-modal-title">{{ $t('object.delete.popup.title.delete_form') }}</h1>
        <div class="mcf-modal-subtitle">{{ $t('object.delete.popup.subtitle.delete_form') }}</div>
      </template>
      <template v-slot:buttons>
        <a href="javascript:;" class="mcf-button-transparent-gray"
           @click.prevent="closeModal">{{ $t('general.cancel') }}</a>
        <a href="javascript:;" class="mcf-button-fill" @click.prevent="onDeleteOwnObject">{{ $t('general.delete') }}</a>
      </template>
    </modal-direction>

    <!--remove object success-->
    <modal-direction
      v-if="deleteObjectSuccessModalOpen"
      v-model="deleteObjectSuccessModalOpen"
      :clickOutside="false"
    >
      <template v-slot:header>
        <div class="mcf-modal-header-icon"><em class="mcf-icon mcf-icon-check2" style="font-size: 17px"></em></div>
      </template>
      <template v-slot:body>
        <h1 class="mcf-modal-title">{{ $t('object.delete.popup.mainTitle.delete_success') }}</h1>
        <div class="mcf-modal-subtitle">{{ $t('object.delete.popup.title.delete_success') }}</div>
      </template>
      <template v-slot:buttons>
        <a href="/show-my-objects" class="mcf-button-transparent">{{ $t('general.close') }}</a>
      </template>
    </modal-direction>

    <div v-if="isRender" class="mcf-info">

      <div class="mcf-object-actions">
        <a href="javascript:;" class="mcf-icon mcf-icon-PDF" v-if="!isEmbedUrl && renderMode === RenderMode.SHOW_PDF"
           @click.prevent="downloadFile()"></a>
        <a href="javascript:;" class="mcf-icon mcf-icon-trash" ref="parentExclude1" v-if="!isEmbedUrl"
           @click.prevent="showModal('deleteObjectModalOpen')"></a>
        <router-link class="mcf-icon mcf-icon-pen" :to="objectEditLink"
                     v-if="!object.completed && renderMode !== 'modify'"></router-link>
      </div>

      <div class="mcf-info-head">
        <router-link :to="objectShowLink">{{ type.name }}</router-link>
      </div>
      <component :is="propertyRendererTag" :key="renderMode + (displaySetting ? displaySetting.id : '')"
                 :renderData="renderData" :objectId="object.id" :isSignRequired="type.signRequired"
                 :isObjectSigned="object.signed" :isObjectCompleted="object.completed" :display-setting="displaySetting"
                 class="mcf-info-content"/>
    </div>
  </div>
</template>

<script>
import ObjectData from '../ObjectData'
import Object from '../../../models/patientData/Object'
import Property from '../../../models/patientData/Property'
import PropertiesShowRenderer from '../properties/PropertiesShowRenderer'
import PropertiesModifyRenderer from '../properties/PropertiesModifyRenderer'
import PropertiesListRenderer from '../properties/PropertiesListRenderer'
import {AutoFillApi} from '@/api/autoFill'
import RenderMode from '../RenderMode'
import PropertiesPdfRenderer from '../properties/PropertiesPdfRenderer'
import ModalDirection from '../../ModalDirection'
import {ObjectsApi} from '@/api/objects'
import PropertiesUploadedPdfRenderer from '../properties/PropertiesUploadedPdfRenderer'
import AttributeType from "../AttributeTypes";
import {Ajax} from "../../../util/ajaxLoader";
import {pdfFileApi} from "@/api/pdfFile";

export default {
  name: 'ObjectRenderer',
  components: {
    'properties-show-renderer': PropertiesShowRenderer,
    'properties-modify-renderer': PropertiesModifyRenderer,
    'properties-list-renderer': PropertiesListRenderer,
    'properties-pdf-renderer': PropertiesPdfRenderer,
    'properties-uploaded-pdf-renderer': PropertiesUploadedPdfRenderer,
    'modal-direction': ModalDirection
  },
  props: {
    object: Object || null,
    renderMode: String || null,
    updateObjectsListEventBus: null,
    displaySetting: null,
  },
  data() {
    return {
      renderData: [],
      isRender: false,
      type: null,
      pageTitle: 'MedicoFolder',
      deleteObjectModalOpen: false,
      deleteObjectSuccessModalOpen: false,
      pdfName: null,
      RenderMode: RenderMode
    }
  },
  metaInfo () {
    return {
      title: this.pageTitle
    }
  },
  computed: {
    propertyRendererTag: function () {
      return 'properties-' + this.renderMode + '-renderer'
    },
    isEmbedUrl () {
      return this.$route.query && this.$route.query.displayType === 'embed'
    },
    isAutoFillEnabled () {
      return !this.$route.query || this.$route.query.autoFill !== 'disabled'
    },
    objectEditLink () {
      if (this.isEmbedUrl) {
        return '/object/edit/' + this.object.id + '?displayType=embed'
      } else {
        return '/object/edit/' + this.object.id
      }
    },
    objectShowLink () {
      if (this.isEmbedUrl) {
        return '/object/show/' + this.object.id + '?displayType=embed'
      } else {
        return '/object/show/' + this.object.id
      }
    }
  },
  async created () {
    await this.getObjectData()
    if (this.renderMode === RenderMode.SHOW_PDF) {
      await this.fetchPdfName()
    }
    this.isRender = true
    this.updatePageTitle()
    this.$emit('is-rendered-object')
  },
  methods: {
    showModal () {
      this.deleteObjectModalOpen = true
    },
    hideModal () {
      this.deleteObjectModalOpen = false
    },
    closeModal () {
      this.$emit('modal-close')
    },
    showNotification (type, title, message) {
      this.$toastr(type, message, title)
    },
    updatePageTitle () {
      this.pageTitle = `${this.type.name} ${this.object.id} - MedicoFolder`
    },
    async onDeleteOwnObject () {
      await ObjectsApi.getInstance().deleteOwnObject(this.object.id)
        .then(() => {
          this.closeModal()
        })
        .then(() => {
          this.deleteObjectSuccessModalOpen = true
        })
    },
    async getObjectData () {
      await this.object.getType()
        .then(type => {
          this.type = type
          return this.type.getAttributes()
        })
        .then(attrs => {
          let actions = [
            attrs.sortByOrderIndex(),
            this.object.getProperties(),
          ]
          if (this.renderMode === RenderMode.MODIFY_ITEM && this.isAutoFillEnabled) {
            let autofill = AutoFillApi.getInstance().getLastValues(this.object.id).catch(() => {
              this.$store.commit('setPageNotFound', true)
              return Promise.reject()
            })
            actions.push(autofill)
          }
          return Promise.all(actions)
        })
        .then(async results => {
          this.renderData = await this.prepareAttributesAndPropertiesForRendering(results)
        });
    },
    async getNestedObjectData (nestedObject) {
      return await nestedObject.getType()
        .then(type => {
          return type.getAttributes()
        })
        .then(attrs => {
          let actions = [
            attrs.sortByOrderIndex(),
            nestedObject.getProperties(),
          ]
          if (this.renderMode === RenderMode.MODIFY_ITEM && this.isAutoFillEnabled) {
            let autofill = AutoFillApi.getInstance().getLastValues(nestedObject.id).catch(() => {
              this.$store.commit('setPageNotFound', true)
            })
            actions.push(autofill)
          }
          return Promise.all(actions)
        })
        .then(results => {
          return this.prepareAttributesAndPropertiesForRendering(results)
        });
    },
    async prepareAttributesAndPropertiesForRendering (results) {
      let attrs = results[0]
      let props = results[1]
      let autoFillOptions = null
      if (results[2] !== undefined) {
        autoFillOptions = results[2].data
      }
      let renderData = [];
      await attrs.each(async (attr, index) => {
        let properties = props.filter(property => property.attributeId === attr.id)
          .sort((p1, p2) => p1.index - p2.index)

        if (attr.list && attr.attributeType === AttributeType.NESTED_OBJECT && properties.length > 1) {
          for (let property of properties) {
            let preparedData = await this.prepareAttributePropertyForRendering(attr, index, [property], autoFillOptions)
            renderData.push(preparedData)
          }
        } else {
          let preparedData = await this.prepareAttributePropertyForRendering(attr, index, properties, autoFillOptions)
          renderData.push(preparedData)
        }

      })
      return renderData
    },
    async prepareAttributePropertyForRendering (attr, attrIndex, properties, autoFillOptions) {
      if (!properties || properties.length === 0) {
        properties = [new Property()]
      }
      let attributeProp = properties.find(el => el.attributeId === attr.id)

      let autoFillData = this.getAutoFillData(attr.id, autoFillOptions)
      let nestedObjectData = null
      if (attr.attributeType === AttributeType.NESTED_OBJECT) {
        if (!attributeProp.value) {
          let nestedObj = await this.createNestedObject(attr)
          attributeProp.value = nestedObj.id
        }
        let obj = new Object({id: attributeProp.value})
        await obj.fetch();
        nestedObjectData = await this.getNestedObjectData(obj).then((data) => data)
      }
      return new ObjectData(attr, properties, autoFillData, false, false, nestedObjectData)
    },
    getAutoFillData (attributeId, autoFillOptions) {
      let autoFillData = null

      if (this.renderMode === RenderMode.MODIFY_ITEM) {
        if (autoFillOptions != null && autoFillOptions.length !== 0) {
          autoFillData = autoFillOptions.filter(option => option.attributeId === attributeId)
        }
      }

      return autoFillData;
    },
    async createNestedObject (attribute) {
      if (attribute.nestedTypeTag != null) {
        return Ajax.getInstance().sendPost(`${process.env.VUE_APP_HEALTHBANK_HOST}api/v0/object/${this.object.id}/attribute/${attribute.id}/nestedObject`).then(
          (response) => {
            return new Object({id: response.data.id, typeId: response.data.typeId})
          }).catch((error) => {
          console.log('Failed try to sendPost createNestedObject!')
          return null
        })
      }
    },
    downloadFile () {
      pdfFileApi.downloadFile(this.object.id, this.displaySetting.id, this.pdfName)
    },
    async fetchPdfName () {
      let fileInfoResp = await pdfFileApi.getFileInfo(this.object.id, this.displaySetting.id)
      let fileInfo = fileInfoResp.data
      if (fileInfo.name != null) {
        this.pdfName = fileInfo.name
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/style/helpers/all-helpers";

.mcf-info {
  position: relative;
  margin: 0 0 20px;

  &-head {
    @include flexbox;
    font-family: $main-font;
    font-size: $info-head-size-mob;
    font-weight: 600;
    color: $main-color;
    padding: $info-header-padding-mob;
    width: 77%;

    a {
      font-family: $main-font;
      font-size: $info-head-size-mob;
      font-weight: 600;
      color: $main-color;
    }

    @include mq(tablet-wide, min) {
      font-size: $info-head-size;
      padding: $info-header-padding;
      a {
        font-size: $info-head-size;
      }
    }
  }

  &-content {
    padding: $info-content-padding;
  }

  @include mq(tablet-wide, min) {
    margin: 0 0 10px;
  }
}

.mcf-object-actions {
  @include flexbox;
  @include justify-content(flex-end);
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 7px;

  a {
    @include inline-flex;
    @include align-items(center);
    @include justify-content(center);
    height: 28px;
    width: 28px;
    border: 1px solid #E6E9F1;
    border-radius: 2px;
    font-size: 16px;
    color: $main-color;
    margin: 0 0 0 5px;
    @include transition($main-transition);

    &.mcf-icon-pen {
      font-size: 16px;
    }
  }

  @include mq(tablet-wide, min) {
    padding-top: 10px;
    a {
      height: 32px;
      width: 32px;
      font-size: 20px;

      &:hover {
        background: rgba(94, 126, 242, .09);
      }
    }
  }
}
</style>
