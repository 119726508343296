<template>
  <div class="mcf-mob-menu-wrap">
    <div class="mcf-mob-menu-content">
      <div class="mcf-mob-menu-hello-title" v-show="userFullName">
        <div>{{$t('navigation.greeting')}}</div>{{userFullName}}
      </div>
      <box/>
      <locale-selector/>
    </div>
  </div>
</template>

<script>
	import box from '../header/Box'
  import { AuthService } from '../../util/authService'
	import localeSelector from '../header/LocaleSelector'

  export default {
	  name: "NavigationMenuMob",
	  components: {
		  'locale-selector': localeSelector,
		  'box': box
	  },
	  computed: {
		  userFullName: function () {
			  return AuthService.getInstance().getUserCurrentName()
		  }
	  }
  }
</script>

<style scoped lang="scss">
  @import "../../assets/style/helpers/all-helpers";

  .mcf-mob-menu {
    &-wrap {
      display: none;
      position: fixed;
      top: $header-height;
      right: 0;
      bottom: 0;
      width: $navigation-width;
      background: $navigation-background;
      box-shadow: $navigation-shadow;
      z-index: 2;
    }

    &-content {
      position: relative;
      overflow: auto;
      min-height: 350px;
      height: 100%;
      padding: 75px 45px 125px 45px;
    }

    &-hello-title {
      font-family: $main-font;
      font-size: 22px;
      font-weight: 500;
      color: $white;
      line-height: 1.23;
    }
  }
</style>
