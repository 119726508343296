import {Ajax} from '../util/ajaxLoader'
import {fileDownloader} from "./fileDownloader";

const PDF_INFO_PATH = 'api/v0/pdf/info/';
const PDF_DOWNLOAD_PATH = 'api/v0/pdf/download/';

class PdfFile {
  constructor() {
    this.ajax = Ajax.getInstance()
  }

  getFileInfo(objectId, displaySettingId) {
    let url = `${PDF_INFO_PATH}${objectId}`
    if (displaySettingId){
      url += `/${displaySettingId}`
    }
    return this.ajax.sendGet(url)
  }

  downloadFile(objectId, displaySettingId, fileName) {
    let url = `${PDF_DOWNLOAD_PATH}${objectId}`
    if (displaySettingId){
      url += `/${displaySettingId}`
    }
    return fileDownloader.downloadFile(url, fileName)
  }
}

export const pdfFileApi = new PdfFile();
