<template>
  <div v-if="tabHash === hash">
    <slot></slot>
  </div>
</template>

<script>
	export default {
		name: 'Tab',
		props: {
			name: {
				required: true
			},
			link: {
				required: true
			},
			hash: {
				required: true
			},
			tabEventBus: {
				required: true
			}
		},
		data() {
			return {
				tabHash: null
			}
		},
		computed: {
			locale() {
				return this.$store.state.locale
			},
			href() {
				if (this.link) {
					let href = this.link.replace(/^\/|\/$/g, '')
					return `${process.env.VUE_APP_HEALTHBANK_HOST}${href}`
				} else {
					return ""
				}
			}
		},
		watch: {
			locale() {
				if (this.tabHash === this.hash) {
					this.tabEventBus.$emit('set-tab-name', this.hash)
				}
			}
		},
		updated() {
			let currentRoute = this.$router.currentRoute.fullPath.replace(`/${this.$store.state.locale}`, '')
			if (this.link === currentRoute) {
				this.tabEventBus.$emit('set-tab-name', this.hash)
			}
		},
		created() {
			this.tabEventBus.$on('set-tab-name', (tabHash) => {
				this.tabHash = tabHash
			})
			let currentRoute = this.$router.currentRoute.fullPath.replace(`/${this.$store.state.locale}`, '')
			if (this.link === currentRoute) {
				this.tabEventBus.$emit('set-tab-name', this.hash)
			}
		}
	}
</script>

<style scoped>

</style>
