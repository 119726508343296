<template>
    <all-content/>
</template>

<script>
	import AllContent from './AllContent';

	export default {
		name: 'ContainerContent',
		components: {
			'all-content': AllContent,
		}
  }
</script>
