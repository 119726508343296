import { helpers } from 'vuelidate/lib/validators'

export const empty = (data) => {
  return data === '' || data === null
}

export const isDate = (param) =>
  helpers.withParams(
    {
      type: 'isDate',
      value: param
    },
    (value) => {
      return (isYear(value), isMonth(value), isDay(value))
    }
  )

export const isYear = (date) => {
  if (date == null || date === '') return true
  return !(date > new Date().getFullYear() + 10 || date < 1920)
}

export const isMonth = (date) => {
  if (date == null || date === '') return true
  return !(date <= 0 || date >= 13)
}

export const isDay = (date) => {
  if (date == null || date === '') return true
  return !(date <= 0 || date >= 32)
}

export const isHours = (date) => {
  if (date == null || date === '') return true
  return !(date < 0 || date >= 25)
}

export const isMinutes = (date) => {
  if (date == null || date === '') return true
  return !(date < 0 || date >= 61)
}

export const isTrue = (param) =>
  helpers.withParams(
    {
      type: 'isTrue',
      value: param
    },
    (value) => {
      return value === true
    }
  )
export const isNotNullBoolean = (param) =>
  helpers.withParams(
    {
      type: 'isNotNullBoolean',
      value: param
    },
    (value) => {
      return value === true || value === false
    }
  )
