import {Ajax} from '../util/ajaxLoader'
import UrlManager from "@/util/urlManager";

const FETCH_PRIVACY_POLICY_PATH = 'api/v2/content/privacy'
const FETCH_USER_AGREEMENT_PATH = 'api/v2/content/agreement'

class ContentData {
  constructor() {
    this.ajax = Ajax.getInstance()
  }

  fetchPrivacyPolicy() {
    return this.ajax.sendGet(UrlManager.composeLocaleUrl(process.env.VUE_APP_SOLUTION_HOST, FETCH_PRIVACY_POLICY_PATH),
      {},
      {
        'X-Api-Locale': '',
        'Authorization': '',
        'X-Api-Key': process.env.VUE_APP_WEB_APP_KEY
      }
    )
      .then((response) => handlePrivacyPolicyResponse(response))
      .catch((e) => {
        console.log(e) //todo handle it
      })
  }

  fetchUserAgreement() {
    return this.ajax.sendGet(UrlManager.composeLocaleUrl(process.env.VUE_APP_SOLUTION_HOST, FETCH_USER_AGREEMENT_PATH),
      {},
      {
        'X-Api-Locale': '',
        'Authorization': '',
        'X-Api-Key': process.env.VUE_APP_WEB_APP_KEY
      }
    )
      .then((response) => handleUserAgreementResponse(response))
      .catch((e) => {
        console.log(e) //todo handle it
      })
  }
}


function handlePrivacyPolicyResponse(response) {
  if (response.data != null && response.data.status === 200) {
    return response.data.object.item.localizedPrivacyPolicy
  }
  return ''
}

function handleUserAgreementResponse(response) {
  if (response.data != null && response.data.status === 200) {
    return response.data.object.item.localizedUserAgreement
  }
  return ''
}

let contentData = null

export const ContentDataApi = {
  getInstance() {
    if (contentData == null) {
      contentData = new ContentData()
    }
    return contentData
  }
}
