<template>
  <div class="mcf-form-column">
    <label class="mcf-label">{{item.attribute.name}}:</label>
    <properties-modify-renderer
               :renderData="item.nestedObjectData"
               is-nested="true" class="mcf-nested-object-wrapper"/>
  </div>
</template>

<script>
import ObjectData from "../../ObjectData";

export default {
  name: "NestedObjectModify",
  props: {
    item: ObjectData,
  },
  components: {
    'properties-modify-renderer': () => import("../PropertiesModifyRenderer")
  },
}
</script>

<style scoped>

</style>
