<template>
  <div class="mcf-form-row mcf-completed">
    <div class="mcf-label">{{ item.attribute.name }}</div>
  </div>
</template>

<script>
import ObjectData from '../../ObjectData'

export default {
  name: 'ImmutableTextShow',
  props: {
    item: ObjectData,
    property: Object,
  },
}
</script>
