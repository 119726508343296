<template>
  <div v-if="$v.form && isShowError" class="mcf-validation-errors-wrapper">
    <div v-for="(i, field) in $v.form.$params" :key="field+i" class="mcf-validation-errors">
      <div v-if="!$v.form[field].required && $v.form[field].$params.required" class="mcf-validation-error">
        {{$t('form.error.required', [name, field !== 'value' ? field : ''])}}
      </div>
      <div v-if="!$v.form[field].decimal && $v.form[field].$params.decimal" class="mcf-validation-error">
        {{$t('form.error.numeric', [name, field])}}
      </div>
      <div v-if="!$v.form[field].minLength && $v.form[field].$params.minLength" class="mcf-validation-error">
        {{$t('form.error.minLength', [name, field, $v.form[field].$params.minLength.min])}}
      </div>
      <div v-if="!$v.form[field].maxLength && $v.form[field].$params.maxLength" class="mcf-validation-error">
        {{$t('form.error.maxLength', [name, field, $v.form[field].$params.maxLength.max])}}
      </div>
      <div v-if="!$v.form[field].minValue && $v.form[field].$params.minValue" class="mcf-validation-error">
        {{$t('form.error.minValue', [name, field, $v.form[field].$params.minValue.min])}}
      </div>
      <div v-if="!$v.form[field].url && $v.form[field].$params.url" class="mcf-validation-error">
        {{$t('form.error.url')}}
      </div>
      <div v-if="!$v.form[field].isDate && 'isDate' in $v.form[field].$params" class="mcf-validation-error">
        {{$t('form.error.date')}}
      </div>
      <div v-if="!$v.form[field].isTrue && 'isTrue' in $v.form[field].$params" class="mcf-validation-error">
        {{$t('form.error.boolean', [name])}}
      </div>
      <div v-if="!$v.form[field].isNotNullBoolean && 'isNotNullBoolean' in $v.form[field].$params" class="mcf-validation-error">
        {{$t('form.error.not_null_boolean', [name])}}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ValidationErrors',
    props: {
      $v: {
        type: Object,
        required: true
      },
      name: {
        type: String,
        required: true
      },
      isShowError: {
        type: Boolean,
        required: true
      },
    },
  }
</script>
<style scoped lang="scss">
  @import "../../../../assets/style/helpers/all-helpers";

  .mcf-validation-error {
    color: $decline;
    padding: 7px 0 0;
  }
</style>
