import { Ajax } from '@/util/ajaxLoader'

const ENUMERATED_ITEM = 'api/v0/enumeratedItem'

class EnumeratedItem {
  constructor () {
    this.ajax = Ajax.getInstance()
  }

  getEnumeratedItemByEnumeratedValuesId (enumeratedValuesId) {
    if (enumeratedValuesId == null) {
      return []
    }
    let url = `${ENUMERATED_ITEM}/enumeratedValues/${enumeratedValuesId}`
    return this.ajax.sendGet(url, {}, { 'Content-Type': 'application/json' })
  }
}

let enumeratedItem = null

export const EnumeratedItemApi = {
  getInstance () {
    if (enumeratedItem == null) {
      enumeratedItem = new EnumeratedItem()
    }
    return enumeratedItem
  }
}
