<template>
  <div class="mcf-textarea-wrapper">
    <label class="mcf-label" v-show="showAttributeName">{{ item.attribute.name }}:</label>
    <input v-model="property.value" class="mcf-input">
  </div>
</template>

<script>
import ObjectData from '../../ObjectData'
import Property from '../../../../models/patientData/Property'

export default {
  name: 'TextCreate',
  props: {
    item: ObjectData,
    property: Object,
  },
  created () {
    this.prepareItemAndProperty()
  },
  computed: {
    showAttributeName () {
      return !this.item.attribute.list
    },
  },
  methods: {
    prepareItemAndProperty () {
      this.item.isReadyToModify = true

      if (this.property.value == null) {
        this.property.value = ''
      }
    }
  },
}
</script>
