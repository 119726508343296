<template>
  <div id="app">
    <div class="mcf-app-wrapper"
         :class="{'mcf-active': this.$store.getters.getMobMenuStatus, 'mcf-embed-page': isEmbedUrl}">
      <head-config/>
      <navigation-menu-mob v-if="!isEmbedUrl"/>

      <div class="mcf-mob-content-wrap">
        <not-search-header v-if="!isEmbedUrl"/>
        <div class="mcf-app-content-wrapper">
          <container-content/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	import AllContent from './components/AllContent'
	import HeadConfig from './components/Head'
	import NoSearchHeader from './components/header/NoSearchHeader'
	import Footer from './components/footer/Footer'
	import NavigationMenu from './components/navigation/NavigationMenu'
	import NavigationMenuMob from './components/navigation/NavigationMenuMob'
	import ContainerContent from './components/ContainerContent'
	import {AuthService} from './util/authService'

	export default {
		components: {
			'all-content': AllContent,
			'container-content': ContainerContent,
			'head-config': HeadConfig,
			'not-search-header': NoSearchHeader,
			'footer-el': Footer,
			'navigation-menu': NavigationMenu,
			'navigation-menu-mob': NavigationMenuMob
		},
	  computed: {
		  isEmbedUrl() {
			  return this.$route.query && this.$route.query.displayType === 'embed'
		  }
	  },
		async created() {
      let authService = AuthService.getInstance()
      authService.setAuthParamsFromUrl()
          .then(() => authService.getAuthorization())
		},
	}
</script>

<style lang="scss">
  @import "./assets/style/style.scss";

  .mcf-app-wrapper {
    &.mcf-embed-page {
      .mcf-app-content-wrapper {
        padding: 0;
      }

      .mcf-content-wrapper {
        margin-top: 0;
        margin-bottom: 0;
        border-radius: 0;
        @include mq(tablet-wide, min) {
          margin-top: 40px;
          margin-bottom: 40px;
          border-radius: 8px;
        }
      }
    }
  }

  .mcf-app-content-wrapper {
    padding: 80px 0 0;
    @include mq(tablet-wide, min) {
      padding: 0;
    }
  }

  @media print {
    .mcf-app-wrapper {
      .mcf-content-wrapper {
        @include box-shadow(none);
        margin: 0;
        padding: 0;
      }

      .mcf-header-name,
      .mcf-header-sign a,
      .mcf-header-burger,
      .mcf-copy-button,
      .mcf-info-box-fixed,
      #mcf-lang-selector,
      .mcf-display-mode-wrapper,
      .mcf-notification,
      .mcf-button-wrapper,
      .mcf-object-tag-list,
      .mcf-object-actions {
        display: none !important;
      }

      .mcf-header-logo-img {
        color: $text-color;
      }

      .mcf-button-fill, .mcf-button-transparent {
        @include box-shadow(none);
      }

      .mcf-form-copy-value .mcf-link {
        max-width: 100% !important;
      }
    }
  }
</style>
