import { render, staticRenderFns } from "./NavigationMenu.vue?vue&type=template&id=817a6e86&scoped=true&"
import script from "./NavigationMenu.vue?vue&type=script&lang=js&"
export * from "./NavigationMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "817a6e86",
  null
  
)

export default component.exports