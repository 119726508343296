<template>
  <div class="mcf-container">
    <div class="mcf-show-threaded-objects-page">
      <h1 class="mcf-objects-title">{{ $t('showMyObjectsPage.title') }}</h1>
      <div v-if="Boolean(objects) && objects.length" class="mcf-show-threaded-objects-page">
        <panel-object-list
          :objects="objects"
          :with-additional-object-info="false"/>
      </div>
      <div v-else-if="Boolean(objects) && objects.length === 0">
        <panel-no-object-renderer/>
      </div>
    </div>
  </div>
</template>

<script>
import PanelObjectList from './object/PanelObjectList'
import {ObjectsApi} from '../api/objects'
import PanelNoObjectRenderer from "@/components/patientData/objects/PanelNoObjectRenderer";

export default {
  name: 'ShowThreadedObjects',
  components: {
    'panel-no-object-renderer': PanelNoObjectRenderer,
    'panel-object-list': PanelObjectList
  },
  data() {
    return {
      objects: null,
    }
  },
  async created() {
    this.objects = await ObjectsApi.getInstance().getThreadObjects(this.$route.params.threadId)
  },
}
</script>

<style scoped lang="scss">
@import "../assets/style/helpers/all-helpers";

.mcf-show-threaded-objects-page {
  width: 100%;
}

</style>
