<template>
  <div class="mcf-form-column mcf-completed">
    <div class="mcf-label" v-show="showAttributeName">{{ item.attribute.name }}</div>
    <div class="mcf-value">{{ getPropertyValue }}</div>
  </div>
</template>

<script>
import ObjectData from '../../ObjectData'

export default {
  name: 'NumericShow',
  props: {
    item: ObjectData,
    property: Object, // vue warn about property is Object (not a Property)
  },
  computed: {
    showAttributeName () {
      return !this.item.attribute.list
    },
    getPropertyValue () {
      if (this.item.attribute.list) {
        return `${this.property.index + 1}. ${this.executePropertyValue}`
      }
      return this.executePropertyValue
    },
    executePropertyValue () {
      if (!this.property.value) {
        return '-'
      }
      return this.property.value
    }
  },
}
</script>
