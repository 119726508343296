/*
General usage:
---
In component add:
{
  ...
  mixins: [mixinLoader]
  ...
}
To manage loading-state:
this.$emit('loading-increment');
this.$emit('loading-decrement');

If you added the mixin in your root-component you can always trigger it from all childs by:
this.$root.$emit('loading-increment');
this.$root.$emit('loading-decrement');
---

In your component-template add:
  ...
<spinner :loading="!!loaderCount" />
  ...
*/

import Spinner from '../components/Spinner'

export const mixinLoader = {
  components: {
    'spinner': Spinner
  },
  data() {
    return {
      isLoading: false,
      loaderCount: 0
    }
  },
  async created() {
    this.$on('loading-increment', () => this.loadingIncrement())
    this.$on('loading-decrement', () => this.loadingDecrement())
  },
  methods: {
    loadingIncrement: function () {
      if (!this.isLoading) {
        this.loaderCount++
        this.isLoading = true
      }
    },
    loadingDecrement: function () {
      this.loaderCount--
      if (this.loaderCount === 0) {
        this.isLoading = false
      }
    }
  }
}
