<template>
  <div class="mcf-home-page-wrapper">
    <img src="@/assets/images/home/home-bg.png" class="mcf-home-bg"/>
    <div class="mcf-container">
      <div class="mcf-home-page">
        <div class="mcf-home-page-top">
          <div class="mcf-home-page-l">
            <div class="mcf-home-title" v-html="$t('homePage.title')"></div>
            <img src="@/assets/images/home/home-folder-mob.png"/>
            <div class="mcf-home-text" v-html="$t('homePage.subTitle')"></div>
            <div class="mcf-button-wrapper">
              <router-link to="/show-my-objects" class="mcf-button-fill">{{$t('homePage.button.open')}}</router-link>
            </div>
          </div>
          <div class="mcf-home-page-r">
            <img src="@/assets/images/home/home-folder.png"/>
          </div>
        </div>
        <div class="mcf-home-page-about">
          <img src="@/assets/images/home/home-about.png" class="mcf-home-page-about-img"/>
          <div class="mcf-home-page-about-text">
            <div class="mcf-home-title-about">{{$t("homePage.about.title")}}</div>
            <div class="mcf-home-text">{{$t("homePage.about.text")}}</div>
          </div>
          <img src="@/assets/images/home/home-about-mob.png" class="mcf-home-page-about-img-mob"/>
        </div>
      </div>
    </div>
    <footer-el/>
  </div>
</template>

<script>
	import BooleanShow from '../components/patientData/properties/show/BooleanShow'
	import NumericShow from '../components/patientData/properties/show/NumericShow'
	import ObjectRenderer from '../components/patientData/objects/ObjectRenderer'
	import RenderMode from '../components/patientData/RenderMode'
	import Footer from '../components/footer/Footer'

	export default {
		name: 'home',
		data() {
			return {
				object: null,
				renderMode: RenderMode.SHOW_ITEM,
				renderModeTwo: RenderMode.MODIFY_ITEM,
				renderModeThree: RenderMode.LIST_ITEM
			}
		},
		components: {
			'boolean-show': BooleanShow,
			'numeric-show': NumericShow,
			'object-renderer': ObjectRenderer,
			'footer-el': Footer,
		},
	}
</script>

<style lang="scss">
  @import "../assets/style/helpers/all-helpers";

  .mcf-home {
    &-page {
      @include flexbox;
      @include flex-direction(column);
      width: 100%;
      padding: 0 24px;

      &-wrapper {
        position: relative;
        z-index: 1;

        .mcf-home-bg {
          display: none;

          &-mob {
            position: absolute;
            top: 0;
            left: 0;
            height: auto;
            width: 95%;
            z-index: -1;
          }
        }
      }

      .mcf-button-fill {
        min-width: 282px;
        padding: 15px;
        margin: 0;
      }
    }

    &-page-top {
      position: relative;
      @include flexbox;
      padding: 30px 0 0;

      &:before {
        @include pseudo;
        top: 0;
        bottom: -75px;
        left: -24px;
        right: 2px;
        @include background('~@/assets/images/home/home-bg-mob2x.png', right bottom);
        @include background-size(100% auto);
        z-index: -1;
      }

      img {
        height: auto;
        width: 100%;
      }
    }

    &-title {
      font-family: $main-font;
      font-weight: 500;
      font-size: 30px;
      color: $text-color;
      letter-spacing: -0.03em;
      line-height: 1.35;

      span {
        color: $main-color;
        font-weight: 600;
        letter-spacing: -0.7px;
      }

      &-about {
        font-family: $main-font;
        font-weight: 500;
        font-size: 26px;
        line-height: 1.53;
        color: $text-color;
        letter-spacing: -0.03em;
        text-align: center;
        margin: 0 0 8px;
      }
    }

    &-text {
      font-family: $main-font;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.94;
      letter-spacing: -0.03em;
      color: rgba(2, 15, 23, 0.6);
    }

    &-page-l {
      flex: 1 0 100%;

      img {
        display: block;
        max-width: 255px;
        margin: 0 auto;
      }
    }

    &-page-r {
      display: none;
    }

    &-page-about {
      padding: 55px 0 0;
    }

    &-page-about-img {
      display: none;

      &-mob {
        display: block;
        margin: 5px auto 0;
      }
    }

    @include mq(tablet, min) {
      &-text {
        width: 80%;
      }
    }
    @include mq(tablet-wide, min) {
      &-page {
        padding: 0;

        &-wrapper {
          .mcf-home-bg {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;

            &-mob {
              display: none;
            }
          }
        }

        .mcf-button-fill {
          min-width: auto;
          padding: 9px 40px;
        }
      }
      &-page-wrapper {
        .mcf-home-bg {
          display: block;
          height: auto;
          width: 72.88%;
          min-height: 702px;
          max-height: 760px;
          min-width: 945px;
        }
      }
      &-page-top {
        padding: 115px 0;

        &:before {
          display: none;
        }

        img {
          max-width: 100%;
        }
      }
      &-title {
        font-size: 40px;

        &-about {
          font-size: 30px;
          line-height: 1.35;
          text-align: left;
          margin: 0;
        }
      }
      &-text {
        font-size: 18px;
        line-height: 2;
        color: rgba(2, 15, 23, 0.6);
        padding: 35px 0;
      }
      &-page-about {
        position: relative;
        padding: 65px 0 0;

        &-text {
          position: absolute;
          top: 200px;
          left: 62%;
          max-width: 35%;

          .mcf-home-text {
            width: 100%;
            padding: 25px 0;
          }
        }

        .mcf-home-page-about-img {
          display: block;
          margin-left: -15%;
          max-height: 840px;
          max-width: 85%;

          &-mob {
            display: none;
          }
        }
      }
      &-title {
        font-size: 50px;
      }
      &-text {
        width: 80%;
      }
      &-page-l {
        flex: 1 0 53%;

        img {
          display: none;
        }
      }
      &-page-r {
        display: block;
        @include flex-grow(1);
        text-align: right;
      }
    }
    @include mq(desktop, min) {
      &-page-about {
        padding: 165px 0 0;

        &-text {
          top: 345px;
        }
      }
      &-text {
        width: 100%;
      }
    }
  }
</style>
