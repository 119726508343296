<template>
  <div class="mcf-base-pagination" v-if="isShow">
    <navigation-button :disabled="isPreviousButtonDisabled" @click.native="previousPage"><i
      class="mcf-pagination-prev mcf-pagination-icon mcf-icon-chevron-up"></i></navigation-button>

    <base-pagination-trigger
      v-for="paginationTrigger in paginationTriggers"
      :class="{
        'mcf-pagination-current':
          paginationTrigger === currentPage + 1
      }"
      :key="paginationTrigger"
      :pageNumber="paginationTrigger"
      @load-by-page="onLoadPage"
    />

    <navigation-button :disabled="isNextButtonDisabled" @click.native="nextPage"><i
      class="mcf-pagination-next mcf-pagination-icon mcf-icon-chevron-up"></i></navigation-button>
  </div>
</template>

<script>
	import NavigationButton from './BasePaginationNavigationButton'
	import BasePaginationTrigger from './BasePaginationTrigger'

	export default {
		name: 'BasePagination',
		components: {
			'navigation-button': NavigationButton,
			'base-pagination-trigger': BasePaginationTrigger
		},
		props: {
			currentPage: {
				type: Number,
				required: true
			},
			pageCount: {
				type: Number,
				required: true
			},
			size: {
				type: Number,
				default: 5
			}
		},
    data(){
		  return{
		    isShow: true
      }
    },
		computed: {
			isPreviousButtonDisabled() {
				return this.currentPage === 0
			},
			isNextButtonDisabled() {
				return this.currentPage === this.pageCount - 1
			},
			paginationTriggers() {
				const currentPage = this.currentPage
				const pageCount = this.pageCount
				const size = this.size
				const visiblePagesThreshold = Math.ceil((size - 1) / 2)
				let pagintationTriggersArray = Array(this.size - 1).fill(0)

        if (pageCount < 2) {
          this.isShow = false
        }
				if (pageCount <= size) {
					pagintationTriggersArray = Array(this.pageCount).fill(0)
					pagintationTriggersArray[0] = 1
					const pagintationTriggers = pagintationTriggersArray.map(
						(paginationTrigger, index) => {
							return pagintationTriggersArray[0] + index
						}
					)
					return pagintationTriggers
				}

				if (currentPage <= visiblePagesThreshold + 1) {
					pagintationTriggersArray[0] = 1
					const pagintationTriggers = pagintationTriggersArray.map(
						(paginationTrigger, index) => {
							return pagintationTriggersArray[0] + index
						}
					)

					pagintationTriggers.push('optionsStart', pageCount)
					return pagintationTriggers
				}

				if (currentPage >= pageCount - visiblePagesThreshold + 1) {
					const pagintationTriggers = pagintationTriggersArray.map(
						(paginationTrigger, index) => {
							return pageCount - index
						}
					)
					pagintationTriggers.reverse().unshift(1, 'optionsEnd')
					return pagintationTriggers
				}

				pagintationTriggersArray[0] = currentPage - visiblePagesThreshold + 1
				const pagintationTriggers = pagintationTriggersArray.map(
					(paginationTrigger, index) => {
						return pagintationTriggersArray[0] + index
					}
				)
				pagintationTriggers.unshift(1, 'optionsStart')
				pagintationTriggers.push('optionsEnd', pageCount)
				return pagintationTriggers
			}
		},
		methods: {
			nextPage() {
				this.$emit('load-next-page')
			},

			onLoadPage(value) {
				this.$emit('load-by-page', value - 1)
			},

			previousPage() {
				this.$emit('load-previous-page')
			}
		}
	}
</script>

<style lang="scss" scoped>
  @import "../../assets/style/helpers/all-helpers";

  .mcf-base-pagination {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    padding: 20px 0;
  }
</style>
