<template>
  <div>
    <modal-direction
      v-if="modalOpen"
      v-model="modalOpen"
      :parentExclude="'parentExclude1'"
      @modal-hide="hideModal"
    >
      <template v-slot:header>
        <div class="mcf-modal-header-icon"><em class="mcf-icon mcf-icon-check2" style="font-size: 17px"></em></div>
      </template>
      <template v-slot:body>
        <h1 class="mcf-modal-title">{{$t("object.display.completed.success.title")}}</h1>
        <h1 class="mcf-modal-subtitle">{{$t("object.display.completed.success")}}</h1>
      </template>
      <template v-slot:buttons>
        <a href="#" class="mcf-button-transparent" @click.prevent="closeModal">{{$t("general.close")}}</a>
      </template>
    </modal-direction>
    <form @submit.prevent="onSaveAndPreview" enctype="multipart/form-data">
      <spinner :loading="!!loaderCount"/>
      <div v-for="(item, i) in renderData" :key="i" v-show="!loaderCount">
        <div v-show="isShowItem(item)">
          <div class="mcf-list-wrap" v-if="allowShowAsList(item)">
            <div class="mcf-label ">{{ item.attribute.name }}</div>
            <draggable :list="item.properties">
              <transition-group>
                <div class="mcf-flex-row-with-btn mcf-flex-align-start" v-for="(property, index) in item.properties" :key="property.index">
                  <component :is="computePropertyTag(item)" :item="item" :objectId="objectId"
                             :property="property" :autoFillData="getAutoFillData(item, property)"/>
                  <div v-if="errors.length > 0" v-for="(error) in errors" class="error">
                    <span>{{ error }}</span>
                  </div>
                  <div class="mcf-list-actions">
                    <a href="javascript:;" class="mcf-icon mcf-icon-trash" @click.prevent="removeProperty(item, index)"></a>
                  </div>
                </div>
              </transition-group>
            </draggable>
            <input class="mcf-button-transparent mcf-button-sm" @click.prevent="addProperty(item)" type="button" :value="$t('attribute.list.add.label')"/>
          </div>
          <div v-else>
            <component :is="computePropertyTag(item)" :item="item" :objectId="objectId"
                       :property="item.properties[0]" :autoFillData="getAutoFillData(item, item.properties[0])"/>
            <div v-if="errors.length > 0" v-for="(error) in errors" class="error">
              <span>{{ error }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isNested" class="mcf-button-wrapper mcf-button-center" v-show="!loaderCount">
        <input type="submit" class="mcf-button-transparent" :value="$t('object.action.save_and_preview.button')">
        <div class="mcf-button-with-tooltip">
          <input type="button" class="mcf-button-fill" ref="parentExclude1" @click.stop="onSaveAndComplete" :value="$t('object.action.save_and_send.button')">
          <div class="mcf-field-alert">
            <i class="mcf-icon mcf-icon-exclamation-mark"></i>
            <span>{{ $t('object.save_and_save.button.notification') }}</span>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from 'vue'
import TextModify from './modification/TextModify'
import BooleanModify from './modification/BooleanModify'
import NotNullBooleanModify from './modification/NotNullBooleanModify'
import LinkModify from './modification/LinkModify'
import NumericModify from './modification/NumericModify'
import DateModify from './modification/DateModify'
import DateTimeModify from './modification/DateTimeModify'
import UploadedModify from './modification/UploadedModify'
import AttributeType from '../AttributeTypes'
import { propertiesApi } from '@/api/properties'
import Property from '../../../models/patientData/Property'
import ImmutableTextModify from './modification/ImmutableTextModify'
import LongTextModify from './modification/LongTextModify'
import EnumModify from './modification/EnumModify'
import NestedObjectModify from "./modification/NestedObjectModify";
import { mixinLoader } from '@/mixins/mixin-loader'
import * as Vuedraggable from 'vuedraggable'
import ModalDirection from "../../../components/ModalDirection";
import {ObjectsApi} from "@/api/objects";

const SELECTORS = {
  ACTIVE_CLASS: 'mcf-active',
}

export default {
  name: 'PropertiesModifyRenderer',
  components: {
    'draggable': Vuedraggable,
    'enum-modify': EnumModify,
    'text-modify': TextModify,
    'long-text-modify': LongTextModify,
    'boolean-modify': BooleanModify,
    'not-null-boolean-modify': NotNullBooleanModify,
    'link-modify': LinkModify,
    'numeric-modify': NumericModify,
    'date-modify': DateModify,
    'date-time-modify': DateTimeModify,
    'immutable-text-modify': ImmutableTextModify,
    'uploaded-modify': UploadedModify,
    'nested-object-modify': NestedObjectModify,
    'modal-direction': ModalDirection
  },
  props: {
    renderData: Array,
    objectId: Number,
    isNested: null
  },
  data () {
    return {
      modalOpen: false,
      errors: [],
    }
  },
  mixins: [mixinLoader],
  computed: {
    isEmbedUrl () {
      return this.$route.query && this.$route.query.displayType === 'embed'
    },
  },
  methods: {
    allowShowAsList (item) {
      let attributeType = item.attribute.attributeType
      return item.attribute.list
          && attributeType !== AttributeType.BOOLEAN
          && attributeType !== AttributeType.NOT_NULL_BOOLEAN
          && attributeType !== AttributeType.IMMUTABLE_TEXT
          && attributeType !== AttributeType.UPLOADED
          && attributeType !== AttributeType.NESTED_OBJECT
    },
    computePropertyTag (item) {
      return AttributeType.resolveTypePrefix(item.attribute.attributeType) + '-modify'
    },
    processNestedObjectData: function (objectData, data) {
      let validatedNestedData = this.validateRenderData(objectData.nestedObjectData)
      let isSubmit = validatedNestedData['isSubmit']
      if (objectData.attribute.list) {
        if (!data[String(objectData.attribute.id)]) {
          data[String(objectData.attribute.id)] = []
        }
        data[String(objectData.attribute.id)].push(validatedNestedData['data'])
      } else {
        data[String(objectData.attribute.id)] = validatedNestedData['data']
      }
      return {data, isSubmit};
    },
    validateRenderData: function (renderData) {
      let data = {}
      let isSubmit = true
      this.showSpinner()

      renderData.forEach((it) => {
        if (!it.attribute.currentDisplaySetting.showInForm) {
          return
        }
        if (!it.isReadyToModify) {
          isSubmit = false
        }

        if (it.validator != null && it.validator.$invalid) {
          it.validator.$touch()
          it.isShowError = true
          isSubmit = false
        }

        if (it.attribute.attributeType === AttributeType.NESTED_OBJECT && it.nestedObjectData?.length > 0) {
          let processedNestedObjectData =  this.processNestedObjectData(it, data);
          data = processedNestedObjectData['data']
          isSubmit = processedNestedObjectData['isSubmit']
        } else {
          data[String(it.attribute.id)] = this.getAttributeValue(it)
        }
      })
      return {data, isSubmit};
    },
    async onSaveAndPreview() {
      const isSuccess = await this.saveObject();

      if (isSuccess) {
        this.$router.push({path: this.objectShowLink()})
      }
    },
    async onSaveAndComplete() {
      const isSuccess = await this.saveObject();
      if (isSuccess) {
        await this.completeObject()
      }
    },
    async saveObject() {
      let {data, isSubmit} = this.validateRenderData(this.renderData);
      let isSuccess = false

      if (isSubmit) {
        await propertiesApi.getInstance().batchPropertiesSave(this.objectId, data).then(response => {
          let props = response.data.map((prop) => new Property(prop))
          this.renderData.forEach((item) => {
            item.properties = props.filter(prop => prop.attributeId === item.attributeId)
          })
          isSuccess = true
        }).catch(data => {
          this.hideSpinner()
          switch (data.response.status) {
            case 400:
              data.response.data.errors.forEach(error => {
                if (error.reason === 'required') {
                  this.renderData.forEach((renderData) => {
                    if (renderData.attribute.id === error.location) {
                      renderData.validator.$touch()
                      renderData.isShowError = true
                    }
                  })
                }
              })
          }
        })
      } else {
        this.hideSpinner()
      }
      Vue.nextTick(() => {
        this.scrollToFirstError()
      })

      return isSuccess
    },
    async completeObject() {
      await ObjectsApi.getInstance().completeObject(this.objectId)
        .then((response) => {
          this.hideSpinner()
          if (response.data != null) {
            this.modalOpen = true
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    closeModal () {
      this.$emit('modal-close')
    },
    hideModal () {
      return Promise.resolve(this.modalOpen = false)
        .then(() => this.$router.push({path: this.objectShowLink()}))
    },
    getAttributeValue (objectData) {
      let attrType = objectData.attribute.attributeType

      if (objectData.attribute.list) {
        return objectData.properties.map(property => this.parsePropertyValue(attrType, property.value))
      }
      let property = objectData.properties[0]
      return this.parsePropertyValue(attrType, property.value)
    },
    parsePropertyValue (attrType, value) {
      if ((attrType === AttributeType.DATE || attrType === AttributeType.DATE_TIME) && value != null) {
        value = Number(new Date(value).getTime())
      }
      return value
    },
    getAutoFillData (item, property) {
      if (!item.autoFillData) {
        return null
      }

      let autoFillData = item.autoFillData.find(data => !data.propertyIndex || data.propertyIndex === property.index)
      if (autoFillData === undefined) {
        return null
      }
      return autoFillData
    },
    objectShowLink () {
      if (this.isEmbedUrl) {
        return '/object/show/' + this.objectId + '?displayType=embed'
      } else {
        return '/object/show/' + this.objectId
      }
    },
    scrollToFirstError () {
      const el = this.$el.getElementsByClassName('mcf-error')[0]

      if (el) {
        el.scrollIntoView({
          behavior: 'smooth'
        })
      }
    },
    addProperty (item) {
      let properties = item.properties
      let newProperty = new Property()

      if (properties.length > 0) {
        newProperty.index = Math.max(...properties.map(prop => prop.index)) + 1
      } else {
        newProperty.index = 0
      }

      this.$set(properties, properties.length, newProperty)
    },
    removeProperty (item, index) {
      let properties = item.properties
      this.$delete(properties, index)
    },
    showSpinner () {
      this.$emit('loading-increment')
    },
    hideSpinner () {
      this.$emit('loading-decrement')
    },
    isShowItem: function (item) {
      return item.attribute.currentDisplaySetting.showInForm
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./../../../assets/style/helpers/all-helpers";

.error {
  color: red;
}

.mcf-button-with-tooltip {
  position: relative;
  z-index: 1;
  @include flexbox;
  @include flex-direction(column);
  @include mq(tablet-wide, min) {
    @include flex-direction(row);
  }

  .mcf-button-fill {
    @include mq(tablet-wide, max) {
      & + .mcf-field-alert {
        opacity: 1;
        visibility: visible;
      }
    }
    @include mq(tablet-wide, min) {
      &:hover {
        & + .mcf-field-alert {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.mcf-field-alert {
  opacity: 0;
  visibility: hidden;
  @include flexbox;
  @include align-self(baseline);
  @include align-items(center);
  color: $static-color;
  min-width: 100%;
  border-radius: 8px;
  border: 1px solid $no-completed-color;
  background: #fff5df;
  font-size: 9px;
  padding: 7px 5px;
  @include mq(tablet-wide, min) {
    position: absolute;
    bottom: 110%;
    left: 50%;
    @include transform(translate(-50%, 0));
    max-width: 180px;
    font-size: 10px;
    padding: 7px 10px;
    @include transition($main-transition);
  }

  .mcf-icon {
    font-size: 12px;
    color: $no-completed-color;
    margin: 0 7px 0 5px;
    @include mq(tablet-wide, min) {
      margin: 0 10px 0 5px;
    }
  }
}
</style>
