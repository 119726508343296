import { Collection } from 'vue-mc'
import Object from './Object'

export default class ObjectList extends Collection {
  options () {
    return {
      model: Object
    }
  }
}
