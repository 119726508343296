<template>
  <div v-if="isRender" class="mcf-form-column mcf-exception-fill mcf-datetime-wrapper"
       :class="setPropertiesClass">
    <label v-show="showAttributeName">{{ item.attribute.name }}:</label>
    <div class="mcf-input-datetime-wrapper">
      <div>
        <input type="text" class="mcf-input" :placeholder="$t('field.day')" v-model="form.day"
               @focusin="focusInDay"
               @focusout="focusOutDay"
               @input="updateValue"/>
        <input type="text" class="mcf-input" :placeholder="$t('field.month')" v-model="form.month"
               @focusin="focusInMonth"
               @focusout="focusOutMonth"
               @input="updateValue"/>
        <input type="text" class="mcf-input" :placeholder="$t('field.year')" v-model="form.year"
               @focusin="focusInYear"
               @focusout="focusOutYear"
               @input="updateValue"/>
      </div>
    </div>
    <validation-errors :$v="$v" :name="item.attribute.name" :isShowError="isShowErrorComputed"/>
  </div>
</template>

<script>
import ObjectData from '../../ObjectData'
import DateFormatter from '../../../../util/date'
import { maxLength, minLength, numeric, required, } from 'vuelidate/lib/validators'
import ValidationErrors from './ValidationErrors'
import { isDay, isMonth, isYear } from '../validators/CustomValidators'

const setForm = (value) => {
  return {
    year: DateFormatter.getFormatYear(value),
    month: DateFormatter.getFormatMonth(value),
    day: DateFormatter.getFormatDay(value)
  }
}

export default {
  name: 'DateModify',
  components: {
    'validation-errors': ValidationErrors
  },
  props: {
    item: ObjectData,
    property: Object,
    autoFillData: Object,
  },
  data () {
    return {
      isRender: false,
      form: {
        year: null,
        month: null,
        day: null
      },
      isFocusedOnYear: false,
      isFocusedOnMonth: false,
      isFocusedOnDay: false,
    }
  },
  validations () {
    let day = {
      numeric,
      maxLength: maxLength(2),
      isDate (value) {
        return isDay(value)
      }
    }
    let month = {
      numeric,
      maxLength: maxLength(2),
      isDate (value) {
        return isMonth(value)
      }
    }
    let year = {
      numeric,
      maxLength: maxLength(4),
      isDate (value) {
        return isYear(value)
      }
    }

    let form = {}
    if (this.item.attribute.currentDisplaySetting.required) {
      day.minLength = minLength(1)
      day.required = required
      month.minLength = minLength(1)
      month.required = required
      year.minLength = minLength(4)
      year.required = required
    }

    form.day = day
    form.month = month
    form.year = year
    return { form: form }
  },
  created () {
    this.setFormValue()
  },
  computed: {
    showAttributeName () {
      return !this.item.attribute.list
    },
    isShowErrorComputed: function () {
      return !(this.isFocusedOnYear || this.isFocusedOnMonth || this.isFocusedOnDay) && this.item.isShowError
    },
    setPropertiesClass () {
      let className = ''
      if (this.$v.form.$invalid && this.isShowErrorComputed) {
        className += ' mcf-error'
      }
      if (this.form.day && this.form.day.toString().length && this.form.month && this.form.month.toString().length && this.form.year && this.form.year.toString().length === 4) {
        className += ' mcf-fill'
      }
      return className
    }
  },
  methods: {
    setFormValue() {
      this.item.validator = this.$v
      this.item.isReadyToModify = true

      if (this.property.value != null) {
        this.form = setForm(new Date(this.property.value))
      } else if (this.autoFillData != null) {
        this.setValue(new Date(this.autoFillData.value))
      }

      this.isRender = true
    },
    setValue: function (value) {
      this.form = setForm(value)
      this.property.value = value.getTime()
    },
    updateValue: function () {
      if (this.form.year && this.form.month && this.form.day) {
        let updatedDate = new Date(Date.UTC(this.form.year, this.form.month - 1, this.form.day))

        this.property.value = updatedDate.getTime()
      } else {
        this.property.value = null
      }
    },
    focusInYear: function () {
      this.isFocusedOnYear = true
    },
    focusOutYear: function () {
      this.isFocusedOnYear = false
    },
    focusInMonth: function () {
      this.isFocusedOnMonth = true
    },
    focusOutMonth: function () {
      this.isFocusedOnMonth = false
    },
    focusInDay: function () {
      this.isFocusedOnDay = true
    },
    focusOutDay: function () {
      this.isFocusedOnDay = false
    }
  }
}
</script>
