<template>
  <div class="mcf-container">
    <!--finalize object modal-->
    <modal-direction
      v-if="modalOpen"
      v-model="modalOpen"
      @modal-hide="hideModal"
    >
      <template v-slot:header>
        <div class="mcf-modal-header-icon"><em class="mcf-icon mcf-icon-check2" style="font-size: 17px"></em></div>
      </template>
      <template v-slot:body>
        <h1 class="mcf-modal-title">{{$t("object.display.completed.success.title")}}</h1>
        <h1 class="mcf-modal-subtitle">{{$t("object.display.completed.success")}}</h1>
      </template>
      <template v-slot:buttons>
        <a href="#" class="mcf-button-transparent" @click.prevent="closeModal">{{$t("general.close")}}</a>
      </template>
    </modal-direction>
    <router-link to="/show-my-objects" class="mcf-breadcrumbs" v-if="!isEmbedUrl">
      <em class="mcf-icon mcf-icon-arrow-right"></em>{{$t('navigation.back_to_forms')}}
    </router-link>
    <div class="mcf-content-wrapper" ref="contentWrapper" v-if="isRender">
      <spinner :loading="!!loaderCount" :showTextOnLongLoading="true"/>
      <div v-if="object != null && !object.completed && notCompletedObjectNotificationHeader" class="mcf-notification mcf-notification-top"
           ref="notificationShow"
           v-show="!loaderCount">
        <i class="mcf-icon mcf-icon-exclamation-mark"></i>
        {{notCompletedObjectNotificationHeader}}
        <div class="mcf-button-wrapper mcf-button-right">
          <input @click="finalizeObject" type="button" class="mcf-button-fill mcf-button-sm" :value="completeBtnLabel">
        </div>
      </div>

      <div v-if="objectDisplaySettings.length > 1 && !loaderCount" class="mcf-display-mode-wrapper">
        <a href="#" v-for="(item, i) in objectDisplaySettings" :key="i" @click.prevent="onDisplaySettingClick(item)"
           :class="[item.id === activeDisplaySetting.id? 'mcf-active' : '', ' mcf-display-mode-item']">
          {{ item.name ? item.name : $t('object.display.'+item.displayMethod.toLowerCase()) }}
        </a>
      </div>

      <object-renderer v-if="Boolean(object)" :object="object" :render-mode="renderMode"
                       :display-setting="activeDisplaySetting"
                       v-on:is-rendered-object="onReadyToRender"/>

      <div v-show="!loaderCount && object != null && !object.completed">
        <div class="mcf-info-box-fixed" ref="fixedBlock">
          <div v-if="notCompletedObjectNotificationFooter" class="mcf-notification">{{notCompletedObjectNotificationFooter}}</div>
          <div class="mcf-button-wrapper mcf-button-center">
            <input @click="moveToEdit" type="button" class="mcf-button-transparent" :value="$t('general.edit')"/>
            <input @click="finalizeObject" type="button" class="mcf-button-fill" :value="completeBtnLabel">
          </div>
        </div>
      </div>

      <div v-show="!loaderCount" class="mcf-bottom-wrapper" ref="bootomWrap">
        <div class="mcf-notification-wrapper" ref="bottomBlock">
          <div class="mcf-notification" v-if="object != null && !object.completed && notCompletedObjectNotificationFooter">
            {{notCompletedObjectNotificationFooter}}
          </div>
          <div class="mcf-submit-date">{{$t("object.display.submitDate")}}:<span>{{signedDate}}</span></div>
        </div>

        <div v-if="object != null && !object.completed" class="mcf-button-wrapper mcf-button-center">
          <input @click="moveToEdit" type="button" class="mcf-button-transparent" :value="$t('general.edit')"/>
          <input @click="finalizeObject" type="button" class="mcf-button-fill" :value="completeBtnLabel">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	import ObjectRenderer from '../../components/patientData/objects/ObjectRenderer'
	import Object from '../../models/patientData/Object'
	import RenderMode from '../../components/patientData/RenderMode'
	import {ObjectsApi} from '@/api/objects'
	import {mixinLoader} from '@/mixins/mixin-loader'
	import DateFormatter from '../../util/date'
	import ModalDirection from '../../components/ModalDirection'

	const SELECTORS = {
		ACTIVE_CLASS: 'mcf-active',
		NO_ACTIVE_CLASS: 'mcf-no-active'
	}

	export default {
		name: 'ObjectShow',
		components: {
			'object-renderer': ObjectRenderer,
			'modal-direction': ModalDirection
		},
		mixins: [mixinLoader],
		data() {
			return {
				object: null,
				objectDisplaySettings: [],
				activeDisplaySetting: null,
				propertiesMode: RenderMode.SHOW_ITEM,
				pdfMode: RenderMode.SHOW_PDF,
				modalOpen: false,
				readyToRender: false,
				isRender: false
			}
		},
		async created() {
			this.$emit('loading-increment');

			if (this.$route.params != null && this.$route.params.id != null) {
        this.$store.commit('setPageNotFound', false)
        let obj = new Object({id: this.$route.params.id})

				await obj.fetch()
          .catch((data) => {
            switch (data.error.response.status) {
              case 404:
                this.$store.commit('setPageNotFound', true)
                return
            }
        })
        if(obj.isNested) {
          this.$store.commit('setPageNotFound', true)
        }

        let allTypeDisplaySettings = await obj.getDisplaySettings();
        this.objectDisplaySettings = allTypeDisplaySettings.filter(it => it.displayPage === "SHOW");
        if (this.objectDisplaySettings.length > 0) {
          this.activeDisplaySetting = this.objectDisplaySettings[0];

				}
        this.object = obj
        this.isRender = true
      } else {
        this.$store.commit('setPageNotFound', true)
			}
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll)
		},
		destroyed() {
			window.removeEventListener('scroll', this.handleScroll)
		},
		computed: {
			signedDate: function () {
				if (this.object != null && this.object.completedDate) {
					return DateFormatter.formatShowDateTime(new Date(this.object.completedDate))
				} else {
					return DateFormatter.formatShowDate(new Date(Date.now()))
				}
			},
			renderMode() {
				if (this.activeDisplaySetting && this.activeDisplaySetting.displayMethod === "PDF") {
					return RenderMode.SHOW_PDF
				} else if (this.activeDisplaySetting && this.activeDisplaySetting.displayMethod === "UPLOADED_PDF") {
					return RenderMode.SHOW_UPLOADED_PDF
				} else {
					return RenderMode.SHOW_ITEM
				}
			},
			isEmbedUrl() {
		    return this.$route.query && this.$route.query.displayType === 'embed'
			},
      notCompletedObjectNotificationHeader() {
        if (this.activeDisplaySetting && this.activeDisplaySetting.notificationHeader) {
          if (!this.activeDisplaySetting.notificationHeader.trim().length) {
            return "";
          }
          return this.activeDisplaySetting.notificationHeader
        }
        return this.$t("object.display.show.notification")
      },
      notCompletedObjectNotificationFooter() {
        if (this.activeDisplaySetting && this.activeDisplaySetting.notificationFooter) {
          if (!this.activeDisplaySetting.notificationFooter.trim().length) {
            return "";
          }
          return this.activeDisplaySetting.notificationFooter
        }
        return this.$t("object.display.submitDate.notification")
      },
      completeBtnLabel(){
        if (this.activeDisplaySetting && this.activeDisplaySetting.completeButtonLabel) {
          return this.activeDisplaySetting.completeButtonLabel;
        }
        return this.$t('object.form.finalize')
      }
		},
		methods: {
			hideModal() {
				this.modalOpen = false
			},
			handleScroll() {
				if (this.loaderCount) {
					return
				}
				if (!this.object.completed) {
					this.recalculateFixedBlock()
				}
			},
			recalculateFixedBlock() {
				let windowHeight = window.innerHeight,
					scrollTop = window.pageYOffset || document.body.scrollTop,
					bottomBlockOffsetTop = this.$refs.bottomBlock.offsetTop,
					contentWrapper = this.$refs.contentWrapper,
					contentWrapperStyle = getComputedStyle(contentWrapper),
					contentWrapperMarginTop = parseInt(contentWrapperStyle.marginTop),
					headerHeight = 80

				if ((scrollTop + windowHeight) >= (bottomBlockOffsetTop + headerHeight + contentWrapperMarginTop)) {
					!this.$refs.fixedBlock.classList.contains(SELECTORS.NO_ACTIVE_CLASS) && this.$refs.fixedBlock.classList.add(SELECTORS.NO_ACTIVE_CLASS)
					this.$refs.bootomWrap.classList.contains(SELECTORS.NO_ACTIVE_CLASS) && this.$refs.bootomWrap.classList.remove(SELECTORS.NO_ACTIVE_CLASS)
				} else {
					this.$refs.fixedBlock.classList.contains(SELECTORS.NO_ACTIVE_CLASS) && this.$refs.fixedBlock.classList.remove(SELECTORS.NO_ACTIVE_CLASS)
					!this.$refs.bootomWrap.classList.contains(SELECTORS.NO_ACTIVE_CLASS) && this.$refs.bootomWrap.classList.add(SELECTORS.NO_ACTIVE_CLASS)
				}
			},
			closeModal() {
				this.$emit('modal-close')
			},
			onReadyToRender() {
				this.readyToRender = true
				this.$emit('loading-decrement')
				this.recalculateFixedBlock()
			},
			moveToEdit() {
				this.$router.push({path: this.objectEditLink()})
			},
			objectEditLink() {
				if (this.isEmbedUrl) {
					return '/object/edit/' + this.object.id + '?displayType=embed'
				} else {
					return '/object/edit/' + this.object.id
				}
			},
			finalizeObject() {
				ObjectsApi.getInstance().completeObject(this.object.id).then((response) => {
					if (response.data != null) {
						this.object.completed = response.data.completed;
						this.modalOpen = true
					}
				}).catch((e) => {
					console.log(e)
				})
			},
			onDisplaySettingClick(activeDisplaySetting) {
				this.activeDisplaySetting = activeDisplaySetting
			}
		},
	}
</script>

<style scoped lang="scss">
  @import "../../assets/style/helpers/all-helpers";

  .mcf-display-mode-wrapper {
    @include flexbox;
    border-radius: 100px;
    background: transparent;
    border: 1px solid $main-color;
    margin: 10px 0;
    overflow: hidden;
  }

  .mcf-display-mode-item {
    position: relative;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    flex: 1 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.05;
    text-align: center;
    color: $main-color;
    min-height: 32px;
    padding: 10px 8px;
    @include transition($main-transition);

    &:not(:last-child):before {
      @include pseudo();
      top: 2px;
      bottom: 2px;
      right: 0;
      width: 1px;
      background: rgba(135, 168, 212, 0.5);
      opacity: 0.75;
    }

    &.mcf-active {
      color: #ffffff;
      background: $main-color;
    }

    @include mq(tablet-wide, min) {
      font-size: 14px;
      min-height: 42px;
      &:hover {
        color: #ffffff;
        background: $main-color;
      }
    }
  }

  .mcf-submit {
    &-date {
      font-family: $main-font;
      font-size: 12px;
      font-weight: 500;
      color: $text-color;
      text-align: center;
      margin: 10px 0;

      span {
        display: inline-block;
        font-weight: 600;
        padding: 0 0 0 10px;
      }
    }

    @include mq(tablet-wide, min) {
      &-date {
        font-size: 14px;
      }
    }
  }

  .mcf-info-box-fixed {
    opacity: 1;
    visibility: visible;
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 24px;
    background: #FFFFFF;
    @include box-shadow(0px -4px 17px rgba(167, 167, 167, 0.1));
    @include transition(opacity 0.1s, visibility 0.1s);
    z-index: 10;
    width: 100%;
    margin: 0 auto;
    @include mq(tablet-wide, min) {
      width: 778px;
    }
    @include mq(desktop, min) {
      width: 1022px;
    }
    @include mq(desktop-wide, min) {
      width: 1013px;
    }

    &.mcf-no-active {
      opacity: 0;
      visibility: hidden;
    }

    .mcf-button-wrapper {
      padding: 5px 0;
    }
  }

  .mcf-bottom-wrapper {
    &.mcf-no-active {
      opacity: 0;
      visibility: hidden;
    }
  }

  .mcf-breadcrumbs {
    position: relative;
    @include flexbox;
    @include align-items(center);
    margin: $content-margin-mob;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 600;
    color: $main-color;
    padding: 0 0 0 37px;

    .mcf-icon {
      @include transform(rotate(180deg));
      @include transition($main-transition);
      position: absolute;
      left: 12px;
      top: 2px;
    }

    @include mq(tablet-wide, min) {
      margin: $content-margin;
      margin-bottom: 0;
      &:hover {
        .mcf-icon {
          left: 9px;
        }
      }
    }
  }
</style>
