import { Collection } from 'vue-mc'
import Tag from './Tag'

export default class TagList extends Collection {
  options () {
    return {
      model: Tag
    }
  }
}
