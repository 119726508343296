import { render, staticRenderFns } from "./MutationHistoryObjectRenderer.vue?vue&type=template&id=1852e496&scoped=true&"
import script from "./MutationHistoryObjectRenderer.vue?vue&type=script&lang=js&"
export * from "./MutationHistoryObjectRenderer.vue?vue&type=script&lang=js&"
import style0 from "./MutationHistoryObjectRenderer.vue?vue&type=style&index=0&id=1852e496&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1852e496",
  null
  
)

export default component.exports