<template>
  <div class="mcf-header-wrapper">
    <div class="mcf-container">
      <div class="mcf-header-position">
        <burger/>
        <router-link to="/" id="mcf-header-logo" @click.native="onLinkClick">
          <img src="@/assets/images/logo/logo-white@2x.png" :class="[logoImgClass,'mcf-header-logo-img']"/>
        </router-link>
        <div class="mcf-header-option">
          <locale-selector/>
          <box/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	import localeSelector from './LocaleSelector'
	import box from './Box'
	import burger from './BurgerLink'

	export default {
		name: 'NoSearchHeader',
		components: {
			'locale-selector': localeSelector,
			'box': box,
			'burger': burger
		},
		computed: {
			currentLocale: function () {
				return this.$store.state.locale
			},
			logoImgClass: function () {
				return 'mcf-logo-img-' + this.currentLocale
			}
		},
	  methods: {
		  onLinkClick() {
			  document.body.classList.remove('mcf-active')
			  this.$store.commit('setMobMenuStatus', false)
		  }
	  }
	}
</script>

<style scoped lang="scss">
  @import "../../assets/style/helpers/all-helpers";

  .mcf-header {
    &-wrapper {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background: $header-background;
      z-index: 10;
      @include mq(tablet-wide, min) {
        position: relative;
        top: initial;
        left: initial;
        right: initial;
      }
    }

    &-position {
      @include flexbox;
      @include align-items(center);
      @include justify-content(space-between);
      height: $header-height;
      width: 100%;
      padding: 0;
      @include mq(tablet-wide, max) {
        padding: 0 16px;
        @include justify-content(flex-start);
      }
    }

    &-option {
      @include flexbox;
      @include align-items(center);
      @include mq(tablet-wide, max) {
        display: none;
      }
    }

    &-logo-img {
      display: inline-block;
      height: 32px;
      width: auto;
    }
  }

</style>
