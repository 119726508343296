export const ClickOutside = {
  bind (el, binding, vnode) {
    el.handleClickOutside = (e) => {
      e.stopPropagation()
      const { handler, exclude, parentExclude } = binding.value

      let clickedOnExcludedEl = false
      let clickedOnExcludedParentEl = false

      // Gives you the ability to exclude certain elements if you want, pass as array of strings to exclude
      if (exclude) {
        exclude.forEach(refName => {
          if (!clickedOnExcludedEl) {
            const excludedEl = vnode.context.$refs[refName]
            clickedOnExcludedEl = excludedEl.contains(e.target)
          }
        })
      }

      if (parentExclude && !parentExclude.includes(undefined)) {
        parentExclude.forEach(refName => {
          if (!clickedOnExcludedEl) {
            const excludedParentEl = vnode.context.$parent.$refs[refName]
            clickedOnExcludedParentEl = excludedParentEl.contains(e.target)
          }
        })
      }

      if (!el.contains(e.target) && !clickedOnExcludedEl && !clickedOnExcludedParentEl) {
        vnode.context[handler]()
      }
    }

    document.addEventListener('click', el.handleClickOutside)
  },

  unbind (el) {
    document.removeEventListener('click', el.handleClickOutside)
  }
}
