<template>
  <span class="mcf-pagination-helper" v-if="typeof pageNumber === 'string'"><i class="mcf-icon mcf-icon-options"></i></span>
  <span class="mcf-pagination-trigger" @click="onClick" v-else>{{ pageNumber }}</span>
</template>

<script>
	export default {
		name: 'BasePaginationTrigger',
		props: {
			pageNumber: {
				required: true
			}
		},
		methods: {
			onClick() {
				this.$emit('load-by-page', this.pageNumber)
			}
		}
	}
</script>

<style lang="scss" scoped>
  @import "../../assets/style/helpers/all-helpers";

  .mcf-pagination-trigger {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    font-family: $main-font;
    font-weight: 400;
    font-size: 12px;
    color: $black;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    border: 1px solid transparent;
    margin: 0 3px;
    @include transition($main-transition);
    cursor: pointer;

    &.mcf-pagination-current {
      color: $white;
      border-color: $main-color;
      background: $main-color;
      cursor: default;
      pointer-events: none;
    }

    @include mq(tablet-wide, min) {
      font-size: 15px;
      height: 28px;
      width: 28px;
      margin: 0 4px;
      &:hover {
        color: $white;
        border-color: $main-color;
        background: $main-color;
      }
    }
  }

  .mcf-pagination-helper {
    @include flexbox;
    @include align-items(flex-end);
    @include justify-content(center);
    height: 22px;
    width: 22px;
    padding: 6px 0;

    .mcf-icon {
      font-size: 2px;
      font-weight: 400;
      color: $black;
    }

    @include mq(tablet-wide, min) {
      height: 28px;
      width: 28px;
      padding: 7px 0;
      .mcf-icon {
        font-size: 3px;
      }
    }
  }
</style>
