import { Ajax } from '@/util/ajaxLoader'
import Object from '../models/patientData/Object'
import ObjectList from '../models/patientData/ObjectList'

const OBJECT_PATH = 'api/v0/object'

const OBJECT_COMPLETE_PATH = 'api/v0/object/complete'

const PERMITTED_OBJECT_PATH = 'api/v0/object/granted'

const USER_OBJECT_COUNT_PATH = 'api/v0/object/count'

const TAGGED_OBJECTS_PATH = 'api/v0/object/tag'

const THREAD_OBJECTS_PATH = 'api/v0/object/thread'

const OBJECT_MUTATION_HISTORY_PATH = 'api/v0/object/mutation-history'

const OBJECT_CURRENT_USER_PROFILE_INFO_PATH = 'api/v0/object/current-profile'

class Objects {
  constructor () {
    this.ajax = Ajax.getInstance()
  }

  createObject (body) {
    return this.ajax.sendPost(OBJECT_PATH, body, { 'Content-Type': 'application/json' })
  }

  completeObject (objectId) {
    let url = OBJECT_COMPLETE_PATH
    if (objectId != null) {
      url = `${url}/${objectId}`
    }
    return this.ajax.sendPut(url, {}, { 'Content-Type': 'application/json' })
  }

  deleteOwnObject (objectId) {
    let url = OBJECT_PATH
    if (objectId != null) {
      url = `${url}/${objectId}`
    }
    return this.ajax.sendDelete(url)
  }

  getCurrentUserProfileInfo () {
    return this.ajax.sendGet(OBJECT_CURRENT_USER_PROFILE_INFO_PATH, {}, { 'Content-Type': 'application/json' })
  }

  countUserObjects () {
    return this.ajax.sendGet(USER_OBJECT_COUNT_PATH).then((response) => {
      if (response.data != null) {
        return response.data
      }
      return 0
    }).catch((e) => {
      console.log(e) //todo handle it
    })
  }

  getUserObjects (page, size) {
    return this.ajax.sendGet(OBJECT_PATH, {
      'page': page,
      'size': size
    }).then((response) => {
      return handleObjectListResponse(response)
    }).catch((e) => {
      console.log(e) //todo handle it
    })
  }

  getPermittedObjects () {
    return this.ajax.sendGet(PERMITTED_OBJECT_PATH).then((response) => {
      return handleObjectListResponse(response)
    }).catch((e) => {
      console.log(e) //todo handle it
    })
  }

  getThreadObjects (threadId) {
    return this.ajax.sendGet(`${THREAD_OBJECTS_PATH}/${threadId}`).then((response) => {
      return handleObjectListResponse(response)
    }).catch((e) => {
      console.log(e) //todo handle it
    })
  }

  getObjectMutationHistory (objectMutationTag) {
    return this.ajax.sendGet(`${OBJECT_MUTATION_HISTORY_PATH}/${objectMutationTag}`).then((response) => {
      return handleObjectListResponse(response)
    }).catch((e) => {
      console.log(e) //todo handle it
    })
  }

  getTaggedObjects (tagId) {
    return this.ajax.sendGet(`${TAGGED_OBJECTS_PATH}/${tagId}`).then((response) => {
      return handleObjectListResponse(response)
    }).catch((e) => {
      console.log(e) //todo handle it
    })
  }
}

function handleObjectListResponse(response) {
  if (response.data != null && response.data.length != null) {
    let list = new ObjectList()
    response.data.forEach((object) => {
      list.add(new Object(object))
    })
    return list
  }
  return null
}

let objects = null

export const ObjectsApi = {
  getInstance () {
    if (objects == null) {
      objects = new Objects()
    }
    return objects
  }
}
