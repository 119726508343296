<template>
  <div v-if="Boolean(objects)" class="mcf-show-permitted-object-page">
    <object-list :objects="objects"></object-list>
  </div>
</template>

<script>
  import ObjectListView from './object/ObjectList'
  import { ObjectsApi } from '../api/objects'

  export default {
    name: 'ShowPermittedObjects',
    components: {
      'object-list': ObjectListView
    },
    data () {
      return {
        objects: null,
      }
    },
    async created () {
      this.objects = await ObjectsApi.getInstance().getPermittedObjects()
    }
  }
</script>

<style scoped>

</style>
