<template>
  <div class="mcf-form-column mcf-completed">
    <div class="mcf-label">{{ item.attribute.name }}</div>
    <div v-if="filename !== '-'">

      <a href="#" class="mcf-value" v-text="filename" :class="{ 'mcf-with-name': filename }"
         @click.prevent="downloadFile"></a>

      <div v-if="isImage">
        <img :src="imgUrl"/>
      </div>
    </div>
    <div v-else>
      <div class="mcf-value">{{ filename }}</div>
    </div>
  </div>
</template>

<script>
import ObjectData from '../../ObjectData'
import { uploadedFileApi } from '@/api/uploadedFiles'

export default {
  name: 'UploadedShow',
  props: {
    item: ObjectData,
    property: Object,
  },
  data () {
    return {
      filename: null,
      imgUrl: null
    }
  },
  async created () {
    await this.fetchFileInfo()

    await this.fetchImage()
  },
  computed: {
    downloadFile () {
      if (this.property && this.property.id) {
        uploadedFileApi.downloadFile(this.property.id, this.filename)
      }
    },
    isImage () {
      if (this.filename) {
        const lowerName = this.filename.toLowerCase()
        const extensions = ['.jpg', '.jpeg', '.png', '.gif']

        for (const extension of extensions) {
          if (lowerName.endsWith(extension)) {
            return true
          }
        }
      }
      return false
    }
  },

  methods: {
    async fetchFileInfo () {
      if (this.property && this.property.id) {
        const resp = await uploadedFileApi.getFileInfo(this.property.id)
        const fileInfo = resp.data
        if (fileInfo) {
          this.filename = fileInfo.name
        }
      }
      if (!this.filename) {
        this.filename = '-'
      }
    },
    async fetchImage () {
      if (!this.isImage) {
        return
      }
      this.imgUrl = await uploadedFileApi.fetchFileInBase64(this.property.id, this.name)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/style/helpers/all-helpers";

.mcf-form {
  &-row {
    a {
      color: $link-color;
      @include transition($main-transition);
    }

    @include mq(tablet-wide, min) {
      a:hover {
        color: $link-hover-color;
      }
    }
  }
}

</style>
