<template>
  <div class="mcf-input-wrapper" v-show="item.isReadyToModify">
    <div class="mcf-label">{{ item.attribute.name }}</div>
    <div class="mcf-radio-wrapper" :class="{'mcf-error': $v.form.value.$invalid && item.isShowError}">
      <input type="radio" :id="getCheckboxId + '-true'"
             :name="getCheckboxId"
             :value="true" v-model="form.value"
             @focusin="dontShowValidationError"
             @focusout="showValidationError"
             @input="updateRequiredValueTrue">
      <label :for="getCheckboxId + '-true'"
             class="mcf-label">{{ $t('general.yes') }}</label>
    </div>
    <div class="mcf-radio-wrapper" :class="{'mcf-error': $v.form.value.$invalid && item.isShowError}">
      <input type="radio" :id="getCheckboxId + '-false'"
             :name="getCheckboxId"
             :value="false" v-model="form.value"
             @focusin="dontShowValidationError"
             @focusout="showValidationError"
             @input="updateRequiredValueFalse">
      <label :for="getCheckboxId + '-false'"
             class="mcf-label">{{ $t('general.no') }}</label>
    </div>
    <validation-errors :$v="$v" :name="item.attribute.name" :isShowError="item.isShowError"/>
  </div>
</template>

<script>
import ObjectData from '../../ObjectData'
import ValidationErrors from './ValidationErrors'
import { isNotNullBoolean } from '../validators/CustomValidators'

export default {
  name: 'NotNullBooleanModify',
  components: {
    'validation-errors': ValidationErrors
  },
  props: {
    item: ObjectData,
    property: Object,
    autoFillData: Object,
  },
  data () {
    return {
      form: {
        value: this.property.value
      }
    }
  },
  validations () {
    return {
      form: {
        value: {
          isNotNullBoolean: isNotNullBoolean()
        }
      }
    }
  },
  created () {
    this.setFormValue()
  },
  computed: {
    getCheckboxId () {
      return `${this.item.attribute.attributeType}-${this.item.attribute.id}`
    },
  },
  methods: {
    setFormValue() {
      this.item.validator = this.$v
      this.item.isReadyToModify = true

      if (this.property.value == null) {
        if (this.autoFillData != null) {
          this.property.value = this.autoFillData.value
        } else if (this.item.attribute.currentDisplaySetting.required === false) {
          this.property.value = false
        }
      }
      this.form.value = this.property.value
    },
    updateRequiredValueTrue () {
      this.form.value = true
      this.property.value = true
    },
    updateRequiredValueFalse () {
      this.form.value = false
      this.property.value = false
    },
    showValidationError () {
      this.item.isShowError = true
    },
    dontShowValidationError () {
      this.item.isShowError = false
    }
  }
}
</script>


