export default class AttributeType {
  static TEXT = 'TEXT'
  static LONG_TEXT = 'LONG_TEXT'
  static NUMERIC = 'NUMERIC'
  static BOOLEAN = 'BOOLEAN'
  static NOT_NULL_BOOLEAN = 'NOT_NULL_BOOLEAN'
  static DATE = 'DATE'
  static DATE_TIME = 'DATE_TIME'
  static LINK = 'LINK'
  static UPLOADED = 'UPLOADED'
  static IMMUTABLE_TEXT = 'IMMUTABLE_TEXT'
  static ENUM = 'ENUM'
  static NESTED_OBJECT = 'NESTED_OBJECT'

  static resolveTypePrefix (attributeType) {
    switch (attributeType) {
      case this.TEXT:
        return 'text'
      case this.LONG_TEXT:
        return 'long-text'
      case this.NUMERIC:
        return 'numeric'
      case this.BOOLEAN:
        return 'boolean'
      case this.NOT_NULL_BOOLEAN:
        return 'not-null-boolean'
      case this.DATE:
        return 'date'
      case this.DATE_TIME:
        return 'date-time'
      case this.LINK:
        return 'link'
      case this.UPLOADED:
        return 'uploaded'
      case this.IMMUTABLE_TEXT:
        return 'immutable-text'
      case this.ENUM:
        return 'enum'
      case this.NESTED_OBJECT:
        return 'nested-object'
      default:
        throw new Error('Unexpected attributeType for resoling tag prefix!')
    }
  }
}
