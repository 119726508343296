<template>
  <div v-if="isRender" class="mcf-info">
    <properties-create-renderer :renderData="renderData" class="mcf-info-content"/>
  </div>
</template>

<script>
import Type from '../../../models/patientData/Type'
import ObjectData from '../ObjectData'
import PropertiesCreateRenderer from '../properties/PropertiesCreateRenderer'
import Property from '@/models/patientData/Property'
import PropertyList from '@/models/patientData/PropertyList'

export default {
  name: 'ObjectCreate',
  props: {
    type: Type || null,
  },
  components: {
    'properties-create-renderer': PropertiesCreateRenderer,
  },
  data () {
    return {
      renderData: [],
      isRender: false,
    }
  },
  async created () {
    await this.prepareAttributesForRendering()
    this.isRender = true
  },
  methods: {
    prepareAttributesForRendering: async function () {
      let attrs = await this.type.getAttributes()
      await attrs.sortByOrderIndex()

      attrs.each((attr, index) => {
        let property = new Property()
        if (attr.list) {
          property.index = 0
        }
        let objectData = new ObjectData(attr, [property], null, false, false)
        this.$set(this.renderData, index, objectData)
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/style/helpers/all-helpers";

.mcf-info {
  margin: 0 0 20px;

  &-content {
    padding: $info-content-padding;
  }
}
</style>
