import { Model } from 'vue-mc'
import Attribute from './Attribute'
import Object from './Object'
import { Ajax } from '@/util/ajaxLoader'
import store from '../../vuex'

export default class Property extends Model {

  defaults () {
    return {
      id: null,
      objectId: null,
      attributeId: null,
      index: null,
      value: null,
      attribute: null
    }
  }

  mutations () {
    return {
      id: (id) => Number(id) || null,
      objectId: (objectId) => Number(objectId) || null,
      attributeId: (attributeId) => Number(attributeId) || null,
      index: (index) => Number(index),
    }
  }

  getAttribute () {
    if (this.attributeId) {
      let attribute = new Attribute({ id: this.attributeId })
      return attribute.fetch().then(() => {
        // this.attribute
        return attribute
      })
    }
    return Promise.reject()
  }

  getObject () {
    if (this.objectId) {
      let object = new Object({ id: this.objectId })
      return object.fetch().then(() => object)
    }
    return Promise.reject()
  }

  async fetch (options = {}) {
    let defaultHeaders = await Ajax.getDefaultHeaders(store)
    options.headers = { ...options.headers, ...defaultHeaders }
    return super.fetch(options)
  }

  routes () {
    return {
      fetch: `${process.env.VUE_APP_HEALTHBANK_HOST}api/v0/property/{id}`,
    }
  }
}
