<template>
</template>

<script>
  export default {
    name: 'Head',
    metaInfo () {
      return {
        title: `MedicoFolder`,
        meta: []
      }
    },
  }
</script>
