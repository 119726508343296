<template>
  <div class="mcf-form-column mcf-completed">
    <div class="mcf-label" v-show="showAttributeName">{{ item.attribute.name }}</div>
    <div class="mcf-value">{{ value }}</div>
  </div>
</template>

<script>
import ObjectData from '../../ObjectData'
import EnumeratedItem from '@/models/patientData/EnumeratedItem'

export default {
  name: 'EnumShow',
  props: {
    item: ObjectData,
    property: Object,
  },
  data() {
    return {
      value: String
    }
  },
  async created () {
    await this.setPropertyValue()
  },
  computed: {
    showAttributeName () {
      return !this.item.attribute.list
    },
  },
  methods: {
    async setPropertyValue () {
      let propertyValue = await this.executePropertyValue()
      if (this.item.attribute.list) {
        this.value = `${this.property.index + 1}. ${propertyValue}`
      } else {
        this.value = propertyValue
      }
    },
    async executePropertyValue () {
      if (!this.property.value) {
        return Promise.resolve('-')
      }
      let enumeratedItem = new EnumeratedItem({ id: this.property.value })
      await enumeratedItem.fetch()
      return enumeratedItem.name
    }
  }
}
</script>

<style scoped>

</style>
