<template>
  <not-found-page v-if="isPageNotFound"/>
  <router-view v-else/>
</template>

<script>
import NotFoundPage from '@/views/error/NotFoundPage'

export default {
  name: 'AllContent',
  components: {
    'not-found-page': NotFoundPage
  },
  computed: {
    isPageNotFound() {
      return this.$store.state.isPageNotFound
    }
  }
}
</script>
