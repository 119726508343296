<template>
  <div class="mcf-form-row">
    <div class="mcf-label" v-html="item.attribute.name"></div>
  </div>
</template>

<script>
import ObjectData from '../../ObjectData'

export default {
  name: 'ImmutableTextModify',
  props: {
    item: ObjectData,
    property: Object,
    autoFillData: Object,
  },
  created () {
    this.item.validator = this.$v
    this.item.isReadyToModify = true
  },
}
</script>
