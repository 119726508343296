<template>
  <div>
    <router-link to="/show-my-objects">{{$t('navigation.links.my_documents')}}</router-link>
    <router-link to="/show-permitted-objects">{{$t('navigation.links.permitted_documents')}}</router-link>
  </div>
</template>

<script>
	export default {
		name: 'NavigationMenu'
	}
</script>

<style scoped>

</style>
