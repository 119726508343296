<template>
  <div class="mcf-input-wrapper mcf-checkbox-wrapper">
    <input type="checkbox" v-model="property.value" :id="getCheckboxId" class="mcf-input"/>
    <label :for="getCheckboxId" class="mcf-label">
      {{ item.attribute.name }}
      <em class="mcf-icon mcf-icon-check"></em>
    </label>
  </div>
</template>

<script>
import ObjectData from '../../ObjectData'
import Property from '../../../../models/patientData/Property'

export default {
  name: 'BooleanCreate',
  props: {
    item: ObjectData,
    property: Object,
  },
  created () {
    this.prepareItemAndProperty()
  },
  computed: {
    showAttributeName () {
      return !this.item.attribute.list || this.property.index === 0
    },
    getCheckboxId () {
      return `${this.item.attribute.attributeType}-${this.item.attribute.id}`
    },
  },
  methods: {
    prepareItemAndProperty () {
      this.item.isReadyToModify = true

      if (this.property.value == null) {
        this.property.value = false
      }
    }
  },
}
</script>
