<template>
  <div class="mcf-container">
    <div class="mcf-show-my-object-page">
      <h1 class="mcf-objects-title">{{$t('showMyObjectsPage.title')}}</h1>
      <panel-object-list
        :objects="objects"
        :page="page"
        :size="size"
        :pageCount="pageCount"
        :updateObjectsListEventBus="updateObjectsListEventBus"
        @load-by-page="pageChangeHandle"
      />
    </div>
  </div>
</template>

<script>
	import {ObjectsApi} from '../api/objects'
	import Vue from 'vue'
	import PanelObjectList from './object/PanelObjectList'

	export default {
		name: 'ShowMyObjects',
		components: {
			'panel-object-list': PanelObjectList,
		},
		data() {
			return {
				page: 0,
				size: 10,
				pageCount: 0,
				objects: null,
				updateObjectsListEventBus: new Vue(),
			}
		},
		async created() {
			this.pageCount = this.calculatePagesCount(await ObjectsApi.getInstance().countUserObjects())
			this.objects = await ObjectsApi.getInstance().getUserObjects(this.page, this.size)

			this.updateObjectsListEventBus.$on('update-objects-list', () => {
				this.updateObjects()
			})
		},
		methods: {
			pageChangeHandle(page) {
				this.page = page
				this.updateObjects()
			},
			calculatePagesCount: function (objectCount) {
				return Math.ceil(objectCount / this.size)
			},
			updateObjects: async function () {
				this.objects = null
				this.objects = await ObjectsApi.getInstance().getUserObjects(this.page, this.size)
			},
		},
	}
</script>

<style scoped lang="scss">
  @import "../assets/style/helpers/all-helpers";

  .mcf-show-my-object-page {
    width: 100%;
  }

</style>
