import { render, staticRenderFns } from "./NestedObjectModify.vue?vue&type=template&id=1754a3a1&scoped=true&"
import script from "./NestedObjectModify.vue?vue&type=script&lang=js&"
export * from "./NestedObjectModify.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1754a3a1",
  null
  
)

export default component.exports