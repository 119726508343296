<template>
  <div class="mcf-form-column mcf-exception-fill" v-if="item.isReadyToModify" :class="setPropertiesClass">
    <div class="mcf-label" v-show="showAttributeName">{{ item.attribute.name }}:</div>
    <div class="mcf-value-state">
      <div class="mcf-select" @blur="open = false">
        <div class="mcf-selected" ref="select" :class="{'mcf-open': open}" @click="open = !open">
          {{ form.value == null ? form.value : form.value.name }}
        </div>
        <div class="mcf-items" :class="{'mcf-select-hide': !open}">
          <div class="mcf-item" v-for="(option, i) of enumItems" :key="i"
              v-closable="{ exclude: ['select'], handler: 'onClose' }"
              @click="updateValue(option)"
              @focusin="dontShowValidationError"
              @focusout="showValidationError">
            {{ option.name }}
          </div>
        </div>
      </div>
    </div>
    <validation-errors :$v="$v" :name="item.attribute.name" :isShowError="item.isShowError"/>
  </div>
</template>

<script>
import ObjectData from '../../ObjectData'
import ValidationErrors from './ValidationErrors'
import EnumeratedItemList from '../../../../models/patientData/EnumeratedItemList'
import { required } from 'vuelidate/lib/validators'
import { ClickOutside } from '@/directives/ClickOutside'

export default {
  name: 'EnumModify',
  directives: {
    'closable': ClickOutside
  },
  components: {
    'validation-errors': ValidationErrors
  },
  props: {
    item: ObjectData,
    property: Object,
    autoFillData: Object,
  },
  data () {
    return {
      enumItems: [],
      open: false,
      form: {
        value: null
      }
    }
  },
  validations () {
    return {
      form: {
        value: {
          required: this.item.attribute.currentDisplaySetting.required ? required : (d) => {
            return true
          }
        }
      }
    }
  },
  async created () {
    await this.setFormValue()
  },
  computed: {
    showAttributeName () {
      return !this.item.attribute.list
    },
    setPropertiesClass () {
      let className = ''
      if (this.$v.form.value.$invalid && this.item.isShowError) {
        className += ' mcf-error'
      }
      if (this.form.value != null) {
        className += ' mcf-fill'
      }

      if (this.open) {
        className += ' mcf-parent-open'
      }
      return className
    }
  },
  methods: {
    async setFormValue () {
      this.item.validator = this.$v
      this.item.isReadyToModify = true
      let selectItems = await EnumeratedItemList.getEnumeratedItemByEnumeratedValuesId(this.item.attribute.enumeratedValuesId)
      if (!selectItems || !selectItems.models) {
        return
      }
      selectItems.models.forEach(item => this.enumItems.push({
        value: item.value,
        name: item.name,
        id: item.id
      }))

      if (this.property.value == null) {
        if (this.autoFillData != null) {
          this.property.value = this.autoFillData.value
        } else {
          this.property.value = null
        }
      }

      if (this.property.value != null) {
        this.form.value = this.enumItems.find(item => {
          if (this.property.value != null) {
            return item.id === this.property.value
          }
          return false
        })
      }
    },
    updateValue (option) {
      this.form.value = option
      this.open = false
      this.property.value = option.value
    },
    showValidationError () {
      this.item.isShowError = true
    },
    dontShowValidationError () {
      this.item.isShowError = false
    },
    onClose () {
      if (this.open) {
        this.open = false
      }
    }
  }
}
</script>
