export default class ObjectData {
  constructor (attribute, properties, autoFillData, isReadyToModify, isShowError, nestedObjectData) {
    this.attribute = attribute
    this.properties = properties
    this.autoFillData = autoFillData
    this.isReadyToModify = isReadyToModify
    this.isShowError = isShowError
    this.nestedObjectData = nestedObjectData
  }
}
