<template>
  <div class="mcf-form-column mcf-completed">
    <div class="mcf-label" v-show="showAttributeName">{{ item.attribute.name }}</div>
    <div class="mcf-value mcf-copy-value" v-if="!getPropertyValue.match(/[\d]+\. -/g)">
      <a :href="getPropertyValue" :title="getPropertyValue" ref="link" target="_blank" class="mcf-link">{{ getPropertyValue }}</a>
      <a href="#" @click.prevent="selectText" class="mcf-copy-button" v-if="getPropertyValue">{{ $t('general.copy') }}</a>
    </div>
    <div class="mcf-value" v-else>
      {{ getPropertyValue }}
    </div>
  </div>
</template>

<script>
import ObjectData from '../../ObjectData'

export default {
  name: 'LinkShow',
  props: {
    item: ObjectData,
    property: Object,
  },
  computed: {
    showAttributeName () {
      return !this.item.attribute.list
    },
    getPropertyValue () {
      if (this.item.attribute.list) {
        return `${this.property.index + 1}. ${this.executePropertyValue}`
      }
      return this.executePropertyValue
    },
    executePropertyValue () {
      if (!this.property.value) {
        return '-'
      }
      return this.property.value
    }
  },
  methods: {
    selectText () {
      if (document.selection) {// IE
        let range = document.body.createTextRange()
        range.moveToElementText(this.$refs.link)
        range.select()
      } else if (window.getSelection) {
        let range = document.createRange()
        range.selectNode(this.$refs.link)
        window.getSelection().removeAllRanges()
        window.getSelection().addRange(range)
      }
      document.execCommand('copy')
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/style/helpers/all-helpers";

.mcf-copy-value {
  @include flexbox;
  @include align-items(center);

  .mcf-copy-button {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    @include flex-shrink(0);
    font-family: $main-font;
    font-weight: 600;
    font-size: 14px;
    color: $white;
    border: 1px solid $main-color;
    background: $main-color;
    @include box-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));
    border-radius: $main-radius;
    padding: 5px 10px;
    margin: 0 0 0 3px;
    @include transition($main-transition);
    cursor: pointer;
  }

  .mcf-link {
    color: $link-color;
    max-width: 185px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    @include transition($main-transition);
  }

  @include mq(tablet-wide, min) {
    .mcf-link {
      max-width: 215px;

      &:hover {
        color: $link-hover-color;
      }
    }
    .mcf-copy-button:hover {
      background: $white;
      color: $main-color;
    }
  }
}

</style>
