<template>
  <form @submit.prevent="saveObject" enctype="multipart/form-data">
    <div v-for="(item) in renderData">
      <div class="mcf-list-wrap" v-if="allowShowAsList(item)">
        <label class="mcf-label">{{ item.attribute.name }}:</label>
        <draggable :list="item.properties">
          <transition-group>
            <div class="mcf-flex-row-with-btn mcf-flex-align-start" v-for="property in item.properties"
                 :key="property.index">
              <component :is="computePropertyTag(item)" :item="item" :property="property"/>
              <div v-if="errors.length > 0" v-for="(error) in errors" class="error">
                <span>{{ error }}</span>
              </div>
              <div class="mcf-list-actions">
                <a href="javascript:;" class="mcf-icon mcf-icon-trash" @click.prevent="removeProperty(item, property.index)"></a>
              </div>
            </div>
          </transition-group>
        </draggable>
        <input class="mcf-button-transparent mcf-button-sm" @click.prevent="addProperty(item)" type="button" :value="$t('attribute.list.add.label')"/>
        <br>
      </div>
      <div v-else>
        <component :is="computePropertyTag(item)" :item="item" :property="item.properties[0]"/>
        <div v-if="errors.length > 0" v-for="(error) in errors" class="error">
          <span>{{ error }}</span>
        </div>
      </div>
    </div>
    <div class="mcf-button-wrapper mcf-button-center">
      <input type="submit" class="mcf-button-fill" :value="$t('general.save')">
    </div>
  </form>
</template>

<script>
import TextCreate from './create/TextCreate'
import BooleanCreate from './create/BooleanCreate'
import LinkCreate from './create/LinkCreate'
import NumericCreate from './create/NumericCreate'
import DateCreate from './create/DateCreate'
import DateTimeCreate from './create/DateTimeCreate'
import UploadedCreate from './create/UploadedCreate'
import AttributeType from '../AttributeTypes'
import {ObjectsApi} from '@/api/objects'
import ImmutableTextCreate from './create/ImmutableTextCreate'
import LongTextCreate from './create/LongTextCreate'
import Property from '@/models/patientData/Property'
import NotNullBooleanCreate from '@/components/patientData/properties/create/NotNullBooleanCreate'
import EnumCreate from '@/components/patientData/properties/create/EnumCreate'
import Vuedraggable from "vuedraggable";

export default {
  name: 'PropertiesCreateRenderer',
  components: {
    'draggable': Vuedraggable,
    'text-create': TextCreate,
    'long-text-create': LongTextCreate,
    'boolean-create': BooleanCreate,
    'not-null-boolean-create': NotNullBooleanCreate,
    'enum-create': EnumCreate,
    'link-create': LinkCreate,
    'numeric-create': NumericCreate,
    'date-create': DateCreate,
    'date-time-create': DateTimeCreate,
    'immutable-text-create': ImmutableTextCreate,
    'uploaded-create': UploadedCreate,
  },
  props: {
    renderData: Array,
  },
  data () {
    return {
      errors: []
    }
  },
  methods: {
    allowShowAsList (item) {
      let attributeType = item.attribute.attributeType
      return item.attribute.list
          && attributeType !== AttributeType.BOOLEAN
          && attributeType !== AttributeType.NOT_NULL_BOOLEAN
          && attributeType !== AttributeType.IMMUTABLE_TEXT
          && attributeType !== AttributeType.UPLOADED
    },
    computePropertyTag (item) {
      return AttributeType.resolveTypePrefix(item.attribute.attributeType) + '-create'
    },
    async saveObject () {
      let data = {}
      let isSubmit = true

      this.renderData.forEach(objectData => {
        if (!objectData.isReadyToModify) {
          isSubmit = false
          return
        }
        data[String(objectData.attribute.id)] = this.getAttributeValue(objectData)
      })

      if (isSubmit) {
        let response = await ObjectsApi.getInstance().createObject(data)
        await this.$router.push({ path: '/object/show/' + response.data.id })
      }
    },
    getAttributeValue (objectData) {
      let attrType = objectData.attribute.attributeType

      if (objectData.attribute.list) {
        return objectData.properties
            .sort((p1, p2) => p1.index - p2.index)
            .map(property => this.parsePropertyValue(attrType, property.value))
      }
      let property = objectData.properties[0]
      return this.parsePropertyValue(attrType, property.value)
    },
    parsePropertyValue(attrType, value) {
      if ((attrType === AttributeType.DATE || attrType === AttributeType.DATE_TIME) && value != null) {
        value = Number(new Date(value).getTime())
      }
      return value
    },
    addProperty (item) {
      let newProperty = new Property()
      let propertiesLength = item.properties.length
      if (item.properties.length > 0) {
        newProperty.index = Math.max(...item.properties.map(prop => prop.index)) + 1
      } else {
        newProperty.index = 0
      }

      this.$set(item.properties, propertiesLength, newProperty)
      // this.$forceUpdate()
    },
    removeProperty(item, index) {
      this.$delete(item.properties, index)
      // this.$forceUpdate()
    },
  }
}
</script>

<style scoped>

</style>
