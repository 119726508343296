<template>
  <div class="mcf-header-sign">
    <div class="mcf-header-name">{{userFullName}}</div>
    <div v-if="isAuthorized">
      <a href="#" class="mcf-header-sign-profile" style="display: none">
        {{$t('auth.box.actions.viewProfile')}}
      </a>
      <a @click.prevent="logout" href="#" class="mcf-header-sign-logout">
        {{$t('auth.box.actions.logout')}}
      </a>
    </div>
    <div v-else>
      <a @click.prevent="login" href="#" class="mcf-header-sign-login">
        {{$t('auth.box.actions.login')}}
      </a>
      <a @click.prevent="register" href="#" class="mcf-header-sign-registration">
        {{$t('auth.box.actions.register')}}
      </a>
    </div>
  </div>
</template>

<script>
import { AuthService } from '@/util/authService'
import UrlManager from '../../util/urlManager'
import Cookies from '@/util/Cookies'

export default {
  name: 'Box',
  computed: {
    isAuthorized: function () {
      return Cookies.getAccessToken() !== null
    },
    userFullName: function () {
      return AuthService.getInstance().getUserCurrentName()
    }
  },
  methods: {
    async logout () {
      await AuthService.getInstance().logout()
      await this.login()
    },
    async login () {
      AuthService.getInstance().redirectToAuthService()
    },
    async register () {
      AuthService.getInstance().redirectToAuthService()
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/style/helpers/all-helpers";

  .mcf-header-sign {
    @include flexbox;
    @include flex-direction(column);
    padding: 100px 0 0;

    a {
      position: relative;
      @include inline-flex;
      @include align-items(center);
      font-family: $main-font;
      font-size: 20px;
      font-weight: 500;
      color: $white;
      line-height: 1.2;
      text-align: center;
      padding: 10px 5px 10px 25px;
      @include transition($main-transition);
      width: 100%;

      &:before {
        font-family: $custom-font;
        position: absolute;
        left: 0;
        top: 50%;
        @include transform(translateY(-50%));
        font-size: 18px;
        color: $white;
      }

      &.mcf-header-sign-logout:before {
        content: $icon-logout;
      }

      &.mcf-header-sign-profile:before {
        content: $icon-user;
      }

      &.mcf-header-sign-registration:before {
        content: $icon-register;
      }

      &.mcf-header-sign-login:before {
        content: $icon-login;
      }
    }

    @include mq(tablet-wide, min) {
      @include flex-direction(row);
      @include align-items(center);
      @include justify-content(flex-end);
      padding: 0;
      a {
        font-size: 14px;
        font-weight: 600;
        padding: 10px 10px;
        margin: 0 0 0 8px;
        width: auto;
        cursor: pointer;
        &:before{
          display: none;
        }

        &:hover {
          color: $service-color;
        }
      }
    }
  }
  .mcf-header-name {
    display: none;
    @include mq(tablet-wide, min) {
      display: block;
      font-family: $main-font;
      font-size: 14px;
      font-weight: 600;
      color: $white;
    }
  }
</style>
