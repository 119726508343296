<template>
  <div class="mcf-object"
       :class="{
       'mcf-objects-by-thread': isRenderPanelBody && withAdditionalObjectInfo,
       'mcf-no-preselected': preselectedObjectId && preselectedObjectId !== object.id}"
  >
    <!--object history-->
    <modal-direction
      v-if="objectHistoryModalOpen"
      v-show="objectHistoryModalIsReady"
      v-model="objectHistoryModalOpen"
      :parentExclude="'parentExclude1'"
      @modal-hide="hideModal"
      @modal-destroyed="modalDestroyed"
    >
      <template v-slot:header>
        <div class="mcf-modal-header-icon"><i class="mcf-icon mcf-icon-history"></i></div>
      </template>
      <template v-slot:body>
        <h1 class="mcf-modal-title">{{$t('object.history.title')}}</h1>

        <template v-for="(object, i) in objectsByMutationHistory.models">
          <mutation-history-object-renderer
            :key="i"
            :object="object"
            @mutation-history-object-init="checkMutationHistory"
            @router-object-by-id="moveToShowById(object.id)"
          />
        </template>
      </template>
      <template v-slot:buttons>
        <a href="javascript:;" class="mcf-button-transparent"
           @click.prevent="closeModal">{{$t('general.cancel')}}</a>
      </template>
    </modal-direction>

    <div class="mcf-object-head" v-if="isRenderPanelHeader">
      <div class="mcf-object-head-left">
        <div class="mcf-object-head-title">
          <div class="mcf-flex-row">
            <div class="mcf-round" :class="objectCompletedStatus"></div>
            <div>{{type.name}}</div>
          </div>
          <div class="mcf-object-head-count" v-if="objectsByThreadCount && isRenderPanelBody">
            {{$t('object.tag.title.files',
            [objectsByThreadCount])}}
          </div>
        </div>
        <div class="mcf-object-date-status">{{objectCompletedDate}}</div>
      </div>
      <div class="mcf-object-head-right">
        <div class="mcf-object-date-status">{{objectCompletedDate}}</div>
        <div class="mcf-object-owner-actions">
          <router-link :to="'/object/show/' + object.id" class="mcf-icon mcf-icon-arrow-right"
                       :title="$t('object.tooltip.show')"></router-link>
          <a href="javascript:;" class="mcf-icon mcf-icon-history" ref="parentExclude1"
             :title="$t('object.history.title')"
             v-if="object.objectMutationTag && (objectsByMutationHistoryCount > 1) && withAdditionalObjectInfo"
             @click="showModal()"></a>
          <a href="javascript:;" class="mcf-icon mcf-icon-layers"
             v-show="object.threadId && (objectsByThreadCount > 1) && withAdditionalObjectInfo"
             :title="$t('object.tooltip.group')"
             @click="showObjectsByThread"></a>
        </div>
        <a href="javascript:;" class="mcf-object-group-head-link"
           @click.prevent="onToggleGroupPanel">
          <i class="mcf-icon mcf-icon-chevron-down"></i>
        </a>
      </div>
    </div>
    <div class="mcf-object-body">
      <spinner :loading="!!loaderCount" :size="'25px'"/>
      <div v-show="!loaderCount">
        <div class="mcf-object-body-inner" v-if="isRenderPanelBody">
          <panel-object-renderer
            v-for="(objectByThread, i) in objectsByThread.models"
            :key="i"
            :preselectedObjectId="object.id"
            :object="objectByThread"
            :render-mode="renderMode"
            :updateObjectsListEventBus="updateObjectsListEventBus"
            @panel-object-header-init="checkPanelRender"
            :withAdditionalObjectInfo="false"
          />
        </div>
      </div>
      <div class="mcf-object-body-bottom"></div>
    </div>
  </div>
</template>

<script>
	import {mixinLoader} from '../../../mixins/mixin-loader'
	import DateFormatter from '../../../util/date'
	import ModalDirection from '../../ModalDirection'
	import {ObjectsApi} from '../../../api/objects'
	import ObjectList from '../../../models/patientData/ObjectList'
	import MutationHistoryObjectRenderer from './MutationHistoryObjectRenderer'

	const SELECTORS = {
		IS_COMPLETED_OBJECT_CLASS: 'mcf-completed',
		IS_NO_COMPLETED_OBJECT_CLASS: 'mcf-no-completed'
	}

	export default {
		name: 'PanelObjectRenderer',
		props: {
			object: Object || null,
			renderMode: String || null,
			preselectedObjectId: Number || null,
			updateObjectsListEventBus: null,
      withAdditionalObjectInfo: {
        type: Boolean,
        required: false,
        default: true
      }
		},
		components: {
			'modal-direction': ModalDirection,
			'mutation-history-object-renderer': MutationHistoryObjectRenderer
		},
		mixins: [mixinLoader],
		data() {
			return {
				isRenderPanelHeader: false,
				isRenderPanelBody: false,
				objectsByThreadCount: null,
				objectsByThread: new ObjectList(),
				objectsByMutationHistory: new ObjectList(),
				objectsByMutationHistoryCount: null,
				type: null,
				objectHistoryModalOpen: false,
				objectHistoryModalIsReady: false,
				linkedObjectId: null,
				objectsByThreadTemp: null,
				panelRenderCount: 0,
				mutationHistoryRenderCount: 0,
			}
		},
		computed: {
			objectCompletedDate: function () {
				if (this.object.completedDate) {
					return this.$t('object.data.completedDate', [DateFormatter.formatShowDate(new Date(this.object.completedDate))])
				} else {
					return ''
				}
			},
			objectCompletedStatus() {
				return this.object.completed ? SELECTORS.IS_COMPLETED_OBJECT_CLASS : SELECTORS.IS_NO_COMPLETED_OBJECT_CLASS
			},
		},
		async created() {
			await this.prepareTypeForRendering()
			if (this.object.threadId && this.withAdditionalObjectInfo) {
				await this.prepareThreadObjectsToRender()
			}

			if (this.object.objectMutationTag && this.withAdditionalObjectInfo) {
				await this.prepareObjectsByMutationHistory()
			}

			this.$emit('panel-object-header-init')
			this.isRenderPanelHeader = true
		},
		methods: {
			checkMutationHistory() {
				this.objectHistoryModalIsReady = false
				this.mutationHistoryRenderCount++
				if (this.mutationHistoryRenderCount === this.objectsByMutationHistoryCount) {
					this.mutationHistoryRenderCount = 0
					this.objectHistoryModalIsReady = true
				}
			},
			checkPanelRender() {
				this.panelRenderCount++
				if (this.panelRenderCount === this.objectsByThreadCount) {
					this.sleep(300)
						.then(() => {
							this.panelRenderCount = 0
							this.hideSpinner()
						})
				}
			},
			onToggleGroupPanel() {
				this.isRenderPanelBody = false
			},
			sleep(time) {
				return new Promise((resolve) => setTimeout(resolve, time))
			},
			showObjectsByThread() {
				this.showSpinner()
				this.objectsByThread = this.objectsByThreadTemp
				this.isRenderPanelBody = true
			},
			showModal() {
				this.objectHistoryModalOpen = true
			},
			moveToShowById(id) {
				this.linkedObjectId = id
				this.hideModal()
			},
			hideModal() {
				this.objectHistoryModalOpen = false
			},
			modalDestroyed() {
				if (this.linkedObjectId) {
					this.$router.push({path: '/object/show/' + this.linkedObjectId})
				}
			},
			closeModal() {
				this.$emit('modal-close')
			},
			showSpinner() {
				this.$emit('loading-increment')
			},
			hideSpinner() {
				this.$emit('loading-decrement')
			},
			showNotification(type, title, message) {
				this.$toastr(type, message, title)
			},
			async prepareTypeForRendering() {
				await this.object.getType()
					.then((type) => {
						this.type = type
					})
			},
			async prepareThreadObjectsToRender() {
				await ObjectsApi.getInstance().getThreadObjects(this.object.threadId)
					.then((objectList) => {
						this.objectsByThreadTemp = objectList
						this.objectsByThreadCount = objectList.models.length
					})
			},
			async prepareObjectsByMutationHistory() {
				await ObjectsApi.getInstance().getObjectMutationHistory(this.object.objectMutationTag)
					.then((objectList) => {
						this.objectsByMutationHistoryCount = objectList.models.length
						this.objectsByMutationHistory = objectList
					})
			},
		}
	}
</script>

<style scoped lang="scss">
  @import "../../../assets/style/helpers/all-helpers";

  .mcf-object {
    margin: 0px 10px 10px;
    @include mq(tablet-wide, min) {
      margin: 0px 30px 16px;
    }

    &-head {
      position: relative;
      @include flexbox;
      @include align-items(center);
      background: #FFFFFF;
      box-shadow: 0px 8px 10px rgba(7, 1, 48, 0.035), 0px 6.6501px 5.32008px rgba(7, 1, 48, 0.0282725);
      border-radius: 8px;
      padding: 8px 12px 8px 24px;
      font-family: $main-font;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.4;
      color: $black;
      @include mq(tablet-wide, min) {
        font-size: 18px;
        padding: 12px 20px 12px 24px;
      }

      &-count {
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        @include flex-shrink(0);
        font-weight: 500;
        font-size: 12px;
        line-height: 1.25;
        color: rgba(2, 15, 23, 0.8);
        margin: 5px 0 0;
        padding: 6px 10px;
        background: #DEF0ED;
        border-radius: 4px;
        @include mq(tablet-wide, min) {
          margin: 0 0 0 10px;
        }
      }

      &-left {
        @include flexbox;
        @include align-items(flex-start);
        @include flex-direction(column);
        @include flex-grow(1);
        padding: 0 5px 0 0px;
        @include mq(tablet-wide, min) {
          .mcf-object-date-status {
            display: none;
          }
        }
      }

      &-right {
        @include flexbox;
        @include align-items(center);
        @include justify-content(flex-end);
        width: 40%;

        .mcf-object-date-status {
          display: none;
        }

        @include mq(tablet-wide, min) {
          @include justify-content(space-between);
          .mcf-object-date-status {
            display: block;
          }
        }
      }

      &-title {
        @include flexbox;
        @include align-items(flex-start);
        @include flex-grow(1);
        @include flex-direction(column);
        line-height: 1.21;
        margin: 5px 0;
        @include mq(tablet-wide, min) {
          @include flex-direction(row);
          @include align-items(center);
          margin: 0;
        }
      }
    }

    &-owner-actions {
      @include flexbox;
      @include align-items(center);
      @include justify-content(flex-end);
      @include flex-grow(1);

      .mcf-icon {
        display: block;
        font-size: 14px;
        color: #B5BFC5;
        margin: 0 6px;
        @include transition($main-transition);

        &.mcf-icon-layers {
          margin-bottom: -1px;
        }

        @include mq(tablet-wide, min) {
          font-size: 20px;
          &:hover {
            color: $main-color;
          }
          &.mcf-icon-history {
            font-size: 18px;
          }
          &.mcf-icon-arrow-right {
            font-size: 15px;
          }
          &.mcf-icon-layers {
            font-size: 20px;
            margin-bottom: -3px;
          }
        }
      }
    }

    &.mcf-objects-by-thread {
      margin-top: 32px;

      .mcf-object-body-bottom {
        display: block;
      }

      .mcf-object-body-inner {
        .mcf-object-body-bottom {
          display: none;
        }
      }

      > .mcf-object-head {
        position: relative;
        border-radius: 0 8px 0 0;
        padding: 14px 12px 14px 34px;

        &:before {
          @include pseudo();
          bottom: 100%;
          left: 0;
          height: 16px;
          width: 158px;
          @include background('~@/assets/images/forms/form-group-head.png', left bottom);
          @include background-size(100% auto);
        }

        &:after {
          @include pseudo();
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          border-radius: 0 8px 8px 8px;
          box-shadow: 0px 8px 10px rgba(7, 1, 48, 0.035), 0px 6.6501px 5.32008px rgba(7, 1, 48, 0.0282725);
          z-index: -1;
        }

        .mcf-object-head-right {
          width: auto;
        }

        .mcf-object-owner-actions {
          display: none;
        }

        .mcf-object-group-head-link {
          @include flexbox;
        }
      }

      @include mq(tablet-wide, min) {
        > .mcf-object-head {
          padding: 12px 20px 12px 54px;
        }
      }
    }

    &.mcf-no-preselected {
      opacity: 0.5;
      @include mq(tablet-wide, min) {
        &:hover {
          opacity: 1;
        }
      }
    }

    &-body {
      position: relative;
      background: #E6F5F5;
      border-radius: 0 0 8px 8px;

      &-inner {
        padding: 16px 0;
        min-height: 80px;
      }

      &-bottom {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 16px;
        background: $white;
        border-radius: 0 0 8px 8px;
        z-index: 1;
      }
    }

    &-date-status {
      font-family: $main-font;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.21;
      color: rgba($text-color, 0.34);
      @include mq(tablet-wide, min) {
        font-size: 14px;
        padding: 0 5px;
      }
    }
  }

  .mcf-round {
    display: block;
    @include align-self(center);
    width: 10px;
    height: 10px;
    min-width: 10px;
    background: $main-color;
    border-radius: 50%;
    margin: 0 4px 0 0;
    @include mq(tablet-wide, min) {
      width: 16px;
      height: 16px;
      min-width: 16px;
      margin: 0 8px 0 0;
    }

    &.mcf-completed {
      background: $completed-color;
    }

    &.mcf-no-completed {
      background: $no-completed-color;
    }
  }

  .mcf-object-group-head-link {
    display: none;
    @include align-items(center);
    @include justify-content(center);
    height: 22px;
    width: 22px;

    .mcf-icon {
      font-size: 6px;
      @include transform(rotate(-180deg));
    }
  }

</style>
