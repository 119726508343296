<template>
  <div class="mcf-form-row mcf-checkbox-wrapper mcf-completed">
    <input type="checkbox" v-model="getPropertyValue" :id="getCheckboxId" class="mcf-input" disabled="disabled"/>
    <label :for="getCheckboxId" class="mcf-label mcf-label-checkbox">
      {{ item.attribute.name }}
      <em class="mcf-icon mcf-icon-check"></em>
    </label>
  </div>
</template>

<script>
import ObjectData from '../../ObjectData'

export default {
  name: 'BooleanShow',
  props: {
    item: ObjectData,
    property: Object,
  },
  computed: {
    getCheckboxId () {
      return `${this.item.attribute.attributeType}-${this.item.attribute.id}`
    },
    getPropertyValue () {
      if (!this.property.value) {
        return false
      }
      return this.property.value
    },
  },
}
</script>

<style scoped lang="scss">
@import "../../../../assets/style/helpers/all-helpers";

.mcf-form {
  &-row {
    &.mcf-checkbox-wrapper {
      font-weight: 400;
    }

    .mcf-value {
      color: $service-color;
    }
  }
}

</style>
