<template>
  <div class="mcf-content-text" v-html="$t('aboutPage.manifesto')">
  </div>
</template>

<script>
    export default {
        name: "Manifesto"
    }
</script>

<style scoped>
</style>
