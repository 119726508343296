<template>
  <button :disabled="disabled" class="mcf-pagination-navigation">
    <slot />
  </button>
</template>

<script>
	export default {
		name: 'NavigationButton',
	  props: {
		  disabled: {
			  type: Boolean,
			  default: false
		  }
	  }
	}
</script>

<style scoped lang="scss">
  @import "../../assets/style/helpers/all-helpers";

  .mcf-pagination{
    &-navigation{
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
      height: 22px;
      width: 22px;
      border-radius: 50%;
      border: 1px solid $main-color;
      background-color: transparent;
      margin: 0 3px;
      &[disabled] {
        opacity: 0.3;
        cursor: default;
      }
      @include mq(tablet-wide, min) {
        height: 30px;
        width: 30px;
        margin: 0 18px;
        &:not([disabled]):hover {
          background: $main-color;
          .mcf-pagination-icon{
            color: $white;
          }
        }
      }
    }
    &-icon{
      font-size: 4px;
      color: $main-color;
      @include mq(tablet-wide, min) {
        font-size: 8px;
      }
    }
    &-prev{
      @include transform(rotate(-90deg));
    }
    &-next{
      @include transform(rotate(90deg));
    }
  }
</style>
