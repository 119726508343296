<template>
  <div class="mcf-static-wrap mcf-static-license">
    <div class="mcf-container">
      <div class="mcf-tab-content">
        <h1>{{tabName}}</h1>
        <tabs-el :tabEventBus="tabEventBus">
          <h1 class="mcf-static-title-mob">{{tabName}}</h1>
          <tab-el :name="$t('legalPage.title')" :link="'/content/legal'" :hash="'legalPage'" :tabEventBus="tabEventBus">
            <router-view></router-view>
          </tab-el>
          <tab-el :name="$t('userAgreement.title')" :link="'/content/legal/userAgreement'" :hash="'userAgreement'"
                  :tabEventBus="tabEventBus">
            <router-view></router-view>
          </tab-el>
        </tabs-el>
      </div>
    </div>
    <footer-el/>
  </div>
</template>

<script>
	import Vue from 'vue'
	import Footer from '../../footer/Footer'
	import Tabs from '../../tabs/Tabs'
	import Tab from '../../tabs/Tab'

	export default {
		name: 'License',
		components: {
			'tabs-el': Tabs,
			'tab-el': Tab,
			'footer-el': Footer
		},
		metaInfo() {
			return {
				title: this.pageTitle
			}
		},
		data() {
			return {
				tabName: null,
				tabEventBus: new Vue(),
				pageTitle: 'MedicoFolder'
			}
		},
		created() {
			this.tabEventBus.$on('set-tab-name', (tabHash) => {
				switch (tabHash) {
					case 'legalPage':
						this.pageTitle = this.$t('page.title.MedicoFolder', [this.$t('legalPage.title')])
						this.tabName = this.$t('legalPage.title')
						break
					case 'userAgreement':
						this.pageTitle = this.$t('page.title.MedicoFolder', [this.$t('userAgreement.title')])
						this.tabName = this.$t('userAgreement.title')
						break
				}
			})
		},
		mounted() {
			window.scrollTo(0, 0)
		},
	}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/helpers/all-helpers";

  .mcf-static-wrap {
    background: #fff;
    min-height: calc(100vh - 80px);
  }

  .mcf-content-text {
    &, &::v-deep p {
      font-size: 18px;
      color: $static-color;
      line-height: 1.9;
      font-family: $main-font;
      font-weight: 400;
    }

    & {
      margin: 0 0 200px;
    }

    &::v-deep p {
      margin: 0 0 35px;
    }
    &::v-deep a{
      color: #5f7ef2;
      &:hover{
        color: darken(#5f7ef2, 20%);
      }
    }
    &::v-deep .msr-table-wrap{
      max-width: 100%;
      overflow: auto;
    }
    &::v-deep .msr-table-flex-table {
      @include flexbox;
      flex-direction: column;
      border: 1px solid #333;
      width: 100%;
      max-width: 100%;
      min-width: 600px;
      margin: 20px auto;
      font-size: 15px;
    }
    &::v-deep .msr-table-flex-row {
      @include flexbox;
      border-top: 1px solid #333;
      &:first-child{
        border-top: none;
      }
    }
    &::v-deep .msr-table-header {
      font-family: $main-font;
      font-weight: 600;
      font-size: 16px;
    }
    &::v-deep .msr-table-flex-cell {
      padding: 10px;
      border-right: 1px solid #333;
    }
    &::v-deep .msr-table-flex-cell:first-child {
      flex: 1;
      width: 30%;
      @media (max-width: 991px) {
        word-break: break-all;
      }
    }
    &::v-deep .msr-table-flex-cell:nth-child(2){
      width: 30%;
    }
    &::v-deep .msr-table-flex-cell:nth-child(3) {
      width: 40%;
      word-break: break-all;
    }
    &::v-deep .msr-table-flex-cell:last-child {
      border-right: none;
    }
    .mcf-static-small {
      font-size: 15px;
      font-weight: 500;
      color: #B3B7BA;
    }

    a:not(.mcf-btn) {
      color: $link-color;

      &:hover {
        color: $link-hover-color;
      }
    }

    &::v-deep h2,
    &::v-deep .mcf-content-h2 {
      font-family: $main-font;
      font-weight: 600;
      font-size: 20px;
      margin: 0 0 12px;
      line-height: 1.1;
    }

    ul {
      margin: 0 0 35px;
      padding: 0;

      li {
        margin: 0 0 28px;
        position: relative;
        list-style-type: none;
        padding: 0 0 0 20px;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 12px;
          background: $link-color;
          width: 7px;
          height: 7px;
          border-radius: 50%;
        }
      }
    }
  }

  .mcf-flex-row {
    @include flexbox;
  }

  .mcf-flex-l {
    width: 49%;
  }

  .mcf-flex-r {
    width: 51%;
    padding: 0 0 0 6%;
    margin: -82px 0 0;
    text-align: right;

    img {
      width: 100%;
    }
  }

  .mcf-static-img-mob {
    display: none;
  }

  .mcf-static-wrap h1.mcf-static-title-mob {
    display: none;
  }

  @include mq(tablet-wide, max) {
    .mcf-static-wrap {
      .mcf-tab-content {
        width: 100%;
        padding: 40px 24px 50px;

        h1 {
          font-size: 30px;
          font-weight: 600;
          text-align: center;
        }
      }

      .msr-content-text {
        &, &::v-deep p {
          font-size: 14px;
          line-height: 2.29;
          margin: 0 0 20px;
        }

        &::v-deep ul li {
          font-size: 14px;
          line-height: 2.29;
          margin: 0 0 7px;
        }
      }

      .msr-content-text::v-deep p {
        font-size: 14px;
        line-height: 2.29;
        margin: 0 0 20px;
      }

      .msr-content-text::v-deep h2 {
        font-size: 16px;
        margin: 0 0 10px;
      }
    }
    &.mcf-static-license h1 {
      display: none;

      &.mcf-static-title-mob {
        display: block;
      }
    }
    .mcf-static-img-mob {
      display: block;
      max-width: 263px;
      margin: 0 auto 24px auto;
    }
  }
</style>
