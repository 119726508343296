<template>
  <div class="mcf-lang-selector-wrapper">
    <ul id="mcf-lang-selector">
      <template v-for="(it, i) in locales">
        <li :key="it+i" :class="[it ===  currentLocale? 'active' : '', 'mcf-btn-lang-switch']">
          <a @click.prevent="onLocaleClick(it)" href="#">{{it}}</a>
        </li>
      </template>
    </ul>

    <modal-direction
      v-if="showReloadWarningWindow"
      v-model="showReloadWarningWindow"
      :parentExclude="'parentExclude1'"
      @modal-hide="hideModal"
    >
      <template v-slot:header>
        <div class="mcf-modal-header-icon mcf-modal-header-decline"><i class="mcf-icon mcf-icon-exclamation-mark"
                                                                       style="font-size: 17px"></i></div>
      </template>
      <template v-slot:body>
        <div class="mcf-modal-subtitle">{{$t('locale.change.warn.message')}}</div>
      </template>
      <template v-slot:buttons>
        <a href="javascript:;" class="mcf-button-transparent-gray"
           @click.prevent="closeModal">{{$t('general.cancel')}}</a>
        <a href="javascript:;" class="mcf-button-fill" @click.prevent="acceptLocaleChangeInModal(lastLocaleSelected)">{{$t('general.continue')}}</a>
      </template>
    </modal-direction>
  </div>
</template>

<script>
  import { ALL_LOCALES } from '../../i18n'
  import ModalDirection from '../ModalDirection'

  export default {
    name: 'LocaleSelector',
    components: {
      'modal-direction': ModalDirection
    },
    data () {
      return {
        showReloadWarningWindow: false,
        lastLocaleSelected: this.currentLocale,
      }
    },
    computed: {
      locales: function () {
        return ALL_LOCALES
      },
      currentLocale: function () {
        return this.$store.state.locale
      },
      isWarningWindowNeeded () {
        let url = window.location.pathname
        return url.indexOf('object/edit/') !== -1 || url.indexOf('object/create/') !== -1
      }
    },

    methods: {
      onLocaleClick (localeVal) {
        this.lastLocaleSelected = localeVal
        if (this.isWarningWindowNeeded) {
          this.showReloadWarningWindow = true
          return
        }
        this.changeLocale(localeVal)
      },
      changeLocale (localeVal) {
        document.body.classList.remove('mcf-active')
        this.$store.commit('setLocale', localeVal)
        this.$store.commit('setMobMenuStatus', false)
        this.$router.changeLocale(localeVal)
      },
      hideModal () {
        this.showReloadWarningWindow = false
      },
      closeModal () {
        this.showReloadWarningWindow = false
        this.$emit('modal-close')
      },
      acceptLocaleChangeInModal (localeVal) {
        this.closeModal()
        this.changeLocale(localeVal)
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../assets/style/helpers/all-helpers";

  #mcf-lang-selector {
    @include flexbox;
  }

  .mcf-lang-selector-wrapper {
    position: absolute;
    bottom: 86px;
    left: 45px;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    @include mq(tablet-wide, min) {
      position: relative;
      left: initial;
      bottom: initial;
      padding: 10px 80px;
    }
  }

  .mcf-btn-lang-switch {
    a {
      position: relative;
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
      font-family: $main-font;
      font-size: 16px;
      font-weight: 500;
      color: rgba($white, 0.9);
      background-color: transparent;
      text-transform: uppercase;
      @include transition($main-transition);
      padding: 0px 13px;
      max-height: 31px;
      margin: 0 2px;
      border: 1px solid rgba($white, 0.3);
      border-radius: 8px;
    }

    &.active {
      a {
        color: $main-color;
        background: $white;
        border-color: $white;
      }
    }

    @include mq(tablet-wide, min) {
      a {
        font-size: 14px;
        font-weight: 600;
        color: $white;
        border: none;
        background: transparent;
        margin: 0 8px;
        padding: 0;
        max-height: initial;

        &:before {
          @include pseudo();
          bottom: -10px;
          left: -7px;
          right: -7px;
          height: 2px;
          background: transparent;
          @include transition($main-transition);
        }
      }
      &.active {
        a {
          color: $service-color;
          background: transparent;

          &:before {
            background: $service-color;
          }
        }
      }
      &:not(.active) a:hover {
        color: $service-color;

        &:before {
          background: $service-color;
        }
      }
    }
  }

</style>
