<template>
  <div>
    <div v-if="isRender" class="mcf-form-column mcf-exception-fill mcf-datetime-wrapper"
         :class="setPropertiesClass">
      <label v-show="showAttributeName">{{ item.attribute.name }}:</label>
      <div class="mcf-input-datetime-wrapper">
        <div>
          <input type="text" class="mcf-input" :placeholder="$t('field.year')" v-model="form.year"
                 @focusin="focusInYear"
                 @focusout="focusOutYear"
                 @input="updateValue"/>
          <input type="text" class="mcf-input" :placeholder="$t('field.month')" v-model="form.month "
                 @focusin="focusInMonth"
                 @focusout="focusOutMonth"
                 @input="updateValue"/>
          <input type="text" class="mcf-input" :placeholder="$t('field.day')" v-model="form.day"
                 @focusin="focusInDay"
                 @focusout="focusOutDay"
                 @input="updateValue"/>
        </div>
        <div class="mcf-time-wrapper">
          <input type="text" class="mcf-input" :placeholder="$t('field.hours')" v-model="form.hours"
                 @focusin="focusInHours"
                 @focusout="focusOutHours"
                 @input="updateValue"/>
          <span class="mcf-separator">:</span>
          <input type="text" class="mcf-input" :placeholder="$t('field.minutes')" v-model="form.minutes"
                 @focusin="focusInMinutes"
                 @focusout="focusOutMinutes"
                 @input="updateValue"/>
        </div>
      </div>
      <validation-errors :$v="$v" :name="item.attribute.name" :isShowError="isShowError"/>
    </div>
  </div>
</template>

<script>
import ObjectData from '../../ObjectData'
import DateFormatter from '../../../../util/date'
import {
  minLength,
  maxLength,
  helpers,
  numeric,
  required
} from 'vuelidate/lib/validators'
import ValidationErrors from './ValidationErrors'
import { isDate, isYear, isMonth, isDay, isHours, isMinutes } from '../validators/CustomValidators'

const setForm = (value) => {
  return {
    year: DateFormatter.getFormatYear(value),
    month: DateFormatter.getFormatMonth(value),
    day: DateFormatter.getFormatDay(value),
    hours: DateFormatter.getFormatHours(value),
    minutes: DateFormatter.getFormatMinutes(value)
  }
}

export default {
  name: 'DateTimeModify',
  components: {
    'validation-errors': ValidationErrors
  },
  props: {
    item: ObjectData,
    property: Object,
    autoFillData: Object,
  },
  data () {
    return {
      isRender: false,
      form: {
        year: 0, // todo: discuss it
        month: 0,
        day: 0,
        hours: 0,
        minutes: 0
      },
      isFocusedOnYear: false,
      isFocusedOnMonth: false,
      isFocusedOnDay: false,
      isFocusedOnHours: false,
      isFocusedOnMinutes: false
    }
  },
  validations () {
    let year = {
      required,
      numeric,
      maxLength: maxLength(4),
      isDate (value) {
        return isYear(value)
      }
    }
    let month = {
      required,
      numeric,
      maxLength: maxLength(2),
      isDate (value) {
        return isMonth(value)
      }
    }
    let day = {
      required,
      numeric,
      maxLength: maxLength(2),
      isDate (value) {
        return isDay(value)
      }
    }
    let hours = {
      required,
      numeric,
      maxLength: maxLength(2),
      isDate (value) {
        return isHours(value)
      }
    }
    let minutes = {
      required,
      numeric,
      maxLength: maxLength(2),
      isDate (value) {
        return isMinutes(value)
      }
    }

    let form = {}
    if (this.item.attribute.currentDisplaySetting.required) {
      year.minLength = minLength(4)
      month.minLength = minLength(1)
      day.minLength = minLength(1)
      hours.minLength = minLength(1)
      minutes.minLength = minLength(1)
    }

    form.year = year
    form.month = month
    form.day = day
    form.hours = hours
    form.minutes = minutes

    return { form: form }
  },
  created () {
    this.setFormValue()
  },
  computed: {
    showAttributeName () {
      return !this.item.attribute.list
    },
    isShowError: function () {
      return !(this.isFocusedOnYear ||
          this.isFocusedOnMonth ||
          this.isFocusedOnDay ||
          this.isFocusedOnHours ||
          this.isFocusedOnMinutes
      )
    },
    setPropertiesClass () {
      let className = ''
      if (this.$v.form.$invalid && this.isShowError) {
        className += ' mcf-error'
      }
      if (this.form.day.toString().length &&
          this.form.month.toString().length &&
          this.form.year.toString().length &&
          this.form.hours.toString().length &&
          this.form.minutes.toString().length) {
        className += ' mcf-fill'
      }
      return className
    }
  },
  methods: {
    setFormValue () {
      this.item.validator = this.$v
      this.item.isReadyToModify = true

      if (this.property.value != null) {
        this.form = setForm(new Date(this.property.value))
      } else if (this.autoFillData != null) {
        this.form = setForm(new Date(this.autoFillData.value))
      }

      this.isRender = true
    },
    updateValue () {
      let updatedDate = new Date(this.form.year, this.form.month - 1, this.form.day, this.form.hours, this.form.minutes)
      this.property.value = updatedDate.getTime()
    },
    focusInYear () {
      this.isFocusedOnYear = true
    },
    focusOutYear () {
      this.isFocusedOnYear = false
    },
    focusInMonth () {
      this.isFocusedOnMonth = true
    },
    focusOutMonth () {
      this.isFocusedOnMonth = false
    },
    focusInDay () {
      this.isFocusedOnDay = true
    },
    focusOutDay () {
      this.isFocusedOnDay = false
    },
    focusInHours () {
      this.isFocusedOnHours = true
    },
    focusOutHours () {
      this.isFocusedOnHours = false
    },
    focusInMinutes () {
      this.isFocusedOnMinutes = true
    },
    focusOutMinutes () {
      this.isFocusedOnMinutes = false
    }
  }
}
</script>

<style scoped lang="scss">

</style>
