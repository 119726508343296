<template>
  <div>
    <div v-for="(item, i) in renderData" :key="i">
      <component v-if="isShowItem(item)" :is="computePropertyTag(item)" :item="item"/>
    </div>
  </div>
</template>
<script>
import TextShow from './show/TextShow'
import BooleanShow from './show/BooleanShow'
import LinkShow from './show/LinkShow'
import NumericShow from './show/NumericShow'
import DateShow from './show/DateShow'
import DateTimeShow from './show/DateTimeShow'
import UploadedShow from './show/UploadedShow'
import AttributeType from '../AttributeTypes'
import ImmutableTextShow from './show/ImmutableTextShow'
import LongTextShow from './show/LongTextShow'
import EnumShow from './show/EnumShow'

export default {
  name: 'PropertiesListRenderer',
  components: {
    'enum-show': EnumShow,
    'text-show': TextShow,
    'long-text-show': LongTextShow,
    'boolean-show': BooleanShow,
    'link-show': LinkShow,
    'numeric-show': NumericShow,
    'date-show': DateShow,
    'date-time-show': DateTimeShow,
    'immutable-text-show': ImmutableTextShow,
    'uploaded-show': UploadedShow,
  },
  props: {
    renderData: Array,
  },
  methods: {
    computePropertyTag: function (item) {
      return AttributeType.resolveTypePrefix(item.attribute.attributeType) + '-show'
    },
    isShowItem: function (item) {
      return item.attribute.currentDisplaySetting.showInList
    }
  }
}
</script>
