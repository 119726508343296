<template>
  <div class="mcf-form-column mcf-completed">
    <div class="mcf-label">{{item.attribute.name}}</div>
    <properties-show-renderer :renderData="item.nestedObjectData"
                              is-nested="true" class="mcf-nested-object-wrapper"/>
  </div>
</template>

<script>
import ObjectData from "../../ObjectData";

export default {
  name: "NestedObjectShow",
  components: {
    'properties-show-renderer': () => import("../PropertiesShowRenderer")
  },
  props: {
    item: ObjectData
  },

}
</script>

<style scoped>

</style>
