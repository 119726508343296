<template>
  <div class="mcf-form-column mcf-completed">
    <div class="mcf-label">{{ item.attribute.name }}</div>
    <div class="mcf-radio-wrapper">
      <input type="radio" :id="getCheckboxId + '-true'"
             disabled="disabled"
             :value="true" v-model="getPropertyValue">
      <label :for="getCheckboxId + '-true'"
             class="mcf-label mcf-label-radio">{{ $t('general.yes') }}</label>
    </div>
    <div class="mcf-radio-wrapper">
      <input type="radio" :id="getCheckboxId + '-false'"
             disabled="disabled"
             :value="false" v-model="getPropertyValue">
      <label :for="getCheckboxId + '-false'"
             class="mcf-label mcf-label-radio">{{ $t('general.no') }}</label>
    </div>
  </div>
</template>

<script>
import ObjectData from '../../ObjectData'

export default {
  name: 'NotNullBooleanShow',
  props: {
    item: ObjectData,
    property: Object,
  },
  computed: {
    getCheckboxId () {
      return `${this.item.attribute.attributeType}-${this.item.attribute.id}`
    },
    getPropertyValue () {
      return this.property.value
    },
  },
}
</script>

