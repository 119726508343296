<template>
  <div>
    <span>{{ item.attribute.name }}</span>:
    <div class="upload-container">
      <file-pond name="file" ref="pond" v-bind:server="server" v-bind:files="myFiles"
                 :label-idle="$t('form.selectFile')"/>

    </div>
  </div>
</template>

<script>
import ObjectData from '../../ObjectData'
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import { Ajax } from '@/util/ajaxLoader'
import { uploadedFileApi } from '@/api/uploadedFiles'

const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
)

export default {
  name: 'UploadedCreate',
  components: {
    'file-pond': FilePond
  },
  props: {
    item: ObjectData,
    property: Object,
  },
  data: function () {
    return {
      file: null,
      myFiles: [],
      server: {
        url: process.env.VUE_APP_HEALTHBANK_HOST,
        process: this.sendFile,
        revert: this.revert,
        remove: (source, load, error) => {
          this.property.value = null
          load()
        }
      }
    }
  },
  computed: {
    url: function () {
      if (this.property == null || this.property.value == null) {
        return null
      }
      return `${process.env.VUE_APP_HEALTHBANK_HOST}file/${this.property.value}`
    }
  },
  async created () {
    if (this.property.id != null) {
      let resp = await uploadedFileApi.getFileInfo(this.property.id)
      try {
        let fileData = {
          source: resp.data.name,
          options: {
            type: 'local',
            file: {
              ...resp.data
            }
          }
        }
        this.myFiles.push(fileData)
      } catch (e) {
        //expected url exception
      }
    }
    this.item.isReadyToModify = true
  },
  methods: {
    async revert (uniqueFileId, load, error) {
      this.property.value = null
      this.item.isReadyToModify = true
    },
    async sendFile (fieldName, file, metadata, load, error, progress, abort, transfer, options) {
      this.item.isReadyToModify = false

      const formData = new FormData()
      formData.append(fieldName, file, file.name)
      let request = new XMLHttpRequest()
      request.open('POST', `${process.env.VUE_APP_HEALTHBANK_HOST}file`)

      const headers = await Ajax.getDefaultHeaders()
      for (let name in headers) {
        request.setRequestHeader(name, headers[name])
      }
      request.upload.onprogress = e => {
        progress(e.lengthComputable, e.loaded, e.total)
      }

      request.onload = () => {
        if (request.status >= 200 && request.status < 300) {
          let fileId = JSON.parse(request.responseText).id

          this.property.value = fileId
          this.item.isReadyToModify = true

          file.id = fileId

          load(fileId)
        } else {
          error('oh no')
        }

        this.item.isReadyToModify = true
      }
      request.send(formData)
      return {
        abort: () => {
          request.abort()
          abort()
        }
      }
    },
  }
}
</script>

<!--//todo test-->
