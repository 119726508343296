import { Collection } from 'vue-mc'
import AttributeDisplaySetting from './AttributeDiplaySetting'

export default class AttributeDisplaySettingList extends Collection {
  options () {
    return {
      model: AttributeDisplaySetting
    }
  }
}
