<template>
  <div>
    <spinner :loading="!!loaderCount"/>

    <div v-for="(item) in uploadedFileProperties" v-show="!loaderCount">
      <uploaded-show v-if="isShowItem(item)" :item="item"/>
    </div>

    <div>
      <div v-if="isSignRequired && !isObjectSigned && isObjectCompleted">
        {{$t('object.sign_pdf.notification')}}
        <a href="javascript:" @click.prevent="onUploadSignedPdfLabelClick()">{{$t('object.sign_pdf.upload')}}</a>
      </div>

      <div class="mcf-upload-container" style="margin-left:auto" v-if="showUploadSignedPdfForm">
        <file-pond name="file" ref="pond" v-bind:server="server"
                   :label-idle="$t('form.selectFile')" accepted-file-types="application/pdf"/>
      </div>
    </div>

    <pdf-preview :pdf-url="pdfUrl" v-on:is-rendered-object="hideSpinner"></pdf-preview>
  </div>
</template>

<script>
  import PdfPreview from '../../pdf/PdfPreview'
  import AttributeType from '../AttributeTypes'
  import UploadedShow from './show/UploadedShow'
  import Spinner from '../../Spinner'
  import {mixinLoader} from '../../../mixins/mixin-loader'
  import {Ajax} from "../../../util/ajaxLoader";

  export default {
    name: 'PropertiesPdfRenderer',
    components: {
      Spinner,
      'uploaded-show': UploadedShow,
      'pdf-preview': PdfPreview
    },
    props: {
      renderData: Array,
      objectId: Number,
      displaySetting: null,
      isSignRequired: false,
      isObjectSigned: false,
      isObjectCompleted: false
    },
    data () {
      return {
        showUploadSignedPdfForm: false,
        server: {
          url: process.env.VUE_APP_HEALTHBANK_HOST,
          process: this.sendFile,
        }
      }
    },
    mixins: [mixinLoader],
    computed: {
      pdfUrl: function () {
        let downloadUrl = `${process.env.VUE_APP_HEALTHBANK_HOST}api/v0/pdf/download/${this.objectId}`
        if (this.displaySetting) {
          downloadUrl += `/${this.displaySetting.id}`
        }
        return downloadUrl
      },
      uploadedFileProperties: function () {
        return this.renderData.filter(it => it.attribute.get('attributeType') === AttributeType.UPLOADED)
      },
      isEmbedUrl() {
        return this.$route.query && this.$route.query.displayType === 'embed'
      }
    },
    methods: {
      hideSpinner () {
        this.$emit('loadingDecrement')
      },
      onUploadSignedPdfLabelClick() {
        if(this.isObjectSigned) return
        this.showUploadSignedPdfForm = !this.showUploadSignedPdfForm
      },
      async sendFile (fieldName, file, metadata, load, error, progress, abort, transfer, options) {
        const formData = new FormData()
        formData.append(fieldName, file, file.name)
        let request = new XMLHttpRequest()
        request.open('POST', `${process.env.VUE_APP_HEALTHBANK_HOST}api/file/sign/object/${this.objectId}`)

        const headers = await Ajax.getDefaultHeaders()
        for (let name in headers) {
          request.setRequestHeader(name, headers[name])
        }
        request.upload.onprogress = e => {
          progress(e.lengthComputable, e.loaded, e.total)
        }

        request.onload = () => {
          if (request.status >= 200 && request.status < 300) {
            this.$router.go(0)
          } else {
            error('oh no')
          }
        }
        request.send(formData)
        return {
          abort: () => {
            request.abort()
            abort()
          }
        }
      },
      isShowItem: function (item) {
        return item.attribute.attributeCode !== 'SIGNED_PDF'
      }
    }
  }
</script>
