<template>
  <object-create v-if="Boolean(type)" :type="type" class="mcf-content-wrapper"/>
</template>

<script>
  import ObjectCreate from '../../components/patientData/objects/ObjectCreate'
  import Type from '../../models/patientData/Type'

  export default {
    name: 'ObjectCreate',
    components: {
      'object-create': ObjectCreate
    },
    data () {
      return {
        type: null
      }
    },
    async created () {
      if (this.$route.params != null && this.$route.params.typeId != null) {
        let type = new Type({ id: this.$route.params.typeId })
        await type.fetch()
        this.type = type
      } else {
        console.log('move to 404 !')
      }
    }
  }
</script>

<style scoped>

</style>
