<template>
  <div>
    <spinner :loading="!!loaderCount"/>

    <div v-if="name && name !== '-'">
      <div v-if="name.toLowerCase().endsWith('.pdf')">
        <div class="mcf-button-wrapper mcf-button-end" v-if="!isEmbedUrl">
          <a href="#" class="mcf-button-fill" @click.prevent="downloadFile()">{{ $t('general.download') }}
            <i class="mcf-icon mcf-icon-PDF"></i>
          </a>
        </div>

        <pdf-preview :pdf-url="pdfUrl" v-on:is-rendered-object="hideSpinner"></pdf-preview>
      </div>
      <div v-else>
        <a href="#" class="mcf-value" v-text="name" :class="{ 'mcf-with-name': name }"
           @click.prevent="downloadFile()"></a>

        <div v-if="isImage">
          <img :src="imgUrl"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PdfPreview from '../../pdf/PdfPreview'
import AttributeType from '../AttributeTypes'
import UploadedShow from './show/UploadedShow'
import Spinner from '../../Spinner'
import { mixinLoader } from '@/mixins/mixin-loader'
import { uploadedFileApi } from '@/api/uploadedFiles'

export default {
  name: 'PropertiesPdfRenderer',
  components: {
    Spinner,
    'uploaded-show': UploadedShow,
    'pdf-preview': PdfPreview
  },
  props: {
    renderData: Array,
    objectId: Number,
    displaySetting: null,
  },
  data () {
    return {
      name: null,
      imgUrl: null
    }
  },
  mixins: [mixinLoader],
  computed: {
    pdfUrl: function () {
      let uploadedProperty = this.uploadedFileProperty()
      if (uploadedProperty) {
        return `${process.env.VUE_APP_HEALTHBANK_HOST}api/file/${uploadedProperty.id}`
      }
    },
    isEmbedUrl () {
      return this.$route.query && this.$route.query.displayType === 'embed'
    },
    isImage () {
      const lowerName = this.name.toLowerCase()
      const extensions = ['.jpg', '.jpeg', '.png', '.gif']

      for (const extension of extensions) {
        if (lowerName.endsWith(extension)) {
          return true
        }
      }
      return false
    }
  },
  async created () {
    this.$emit('loadingIncrement')
    await this.fetchPdfName()
    await this.fetchImage()
  },
  methods: {
    async fetchImage () {
      if (!this.isImage) {
        return
      }
      const uploadedProperty = this.uploadedFileProperty()
      if (uploadedProperty != null && uploadedProperty.id != null) {
        this.imgUrl = await uploadedFileApi.fetchFileInBase64(uploadedProperty.id, this.name)
      }
    },

    uploadedFileProperty () {
      let uploadedProperties = this.renderData.filter(it => it.attribute.get('attributeType') === AttributeType.UPLOADED)
      if (uploadedProperties.length) {
        return uploadedProperties[0].properties[0]
      }
    },
    async downloadFile () {
      let uploadedProperty = this.uploadedFileProperty()
      if (uploadedProperty != null && uploadedProperty.id != null) {
        await uploadedFileApi.downloadFile(uploadedProperty.id, this.name)
      }
    },
    async fetchPdfName () {
      let uploadedProperty = this.uploadedFileProperty()
      if (uploadedProperty != null && uploadedProperty.id != null) {
        let resp = await uploadedFileApi.getFileInfo(uploadedProperty.id)
        let fileInfo = resp.data
        if (fileInfo.name != null) {
          this.name = fileInfo.name
        }
      }
      if (uploadedProperty.value === null) {
        this.name = '-'
      }
    },
    hideSpinner () {
      this.$emit('loadingDecrement')
    }
  }
}
</script>
