<template>
  <div class="mcf-static-wrap mcf-about-static">
    <div class="mcf-container">
      <div class="mcf-tab-content">
        <h1>{{tabName}}</h1>
        <div class="mcf-flex-row">
          <div class="mcf-flex-l">
            <tabs-el :tabEventBus="tabEventBus">
              <h1 class="mcf-static-title-mob">{{tabName}}</h1>
              <div class="mcf-static-img-mob"><img
                src="@/assets/images/static/doctors-about@2x.png"/></div>
              <tab-el :name="$t('aboutPage.tabs.title')" :link="'/content/about'" :hash="'aboutPageTitle'"
                      :tabEventBus="tabEventBus">
                <router-view></router-view>
              </tab-el>
              <tab-el :name="$t('aboutPage.tabs.title2')" :link="'/content/about/impressum'" :hash="'aboutPageTitle2'"
                      :tabEventBus="tabEventBus">
                <router-view></router-view>
              </tab-el>
              <tab-el :name="$t('aboutPage.tabs.title3')" :link="'/content/about/contacts'" :hash="'aboutPageTitle3'"
                      :tabEventBus="tabEventBus">
                <router-view></router-view>
              </tab-el>
            </tabs-el>
          </div>
          <div class="mcf-flex-r">
            <img src="@/assets/images/static/doctors-about@2x.png"/>
          </div>
        </div>
      </div>
    </div>
    <footer-el/>
  </div>
</template>

<script>
	import Vue from 'vue'
	import Tabs from '../../tabs/Tabs'
	import Tab from '../../tabs/Tab'
	import Footer from '../../footer/Footer'

	export default {
		name: 'About',
		metaInfo() {
			return {
				title: this.pageTitle
			}
		},
		components: {
			'tabs-el': Tabs,
			'tab-el': Tab,
			'footer-el': Footer
		},
		data() {
			return {
				tabName: null,
				tabEventBus: new Vue(),
				pageTitle: 'MedicoFolder'
			}
		},
		created() {
			this.tabEventBus.$on('set-tab-name', (tabHash) => {
				switch (tabHash) {
					case 'aboutPageTitle':
						this.pageTitle = this.$t('page.title.MedicoFolder', [this.$t('aboutPage.tabs.title')])
						this.tabName = this.$t('aboutPage.tabs.title')
						break
					case 'aboutPageTitle2':
						this.pageTitle = this.$t('page.title.MedicoFolder', [this.$t('aboutPage.tabs.title2')])
						this.tabName = this.$t('aboutPage.tabs.title2')
						break
					case 'aboutPageTitle3':
						this.pageTitle = this.$t('page.title.MedicoFolder', [this.$t('aboutPage.tabs.title3')])
						this.tabName = this.$t('aboutPage.tabs.title3')
						break
				}
			})
		},
		mounted() {
			window.scrollTo(0, 0)
		},
	}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/helpers/all-helpers";

  .mcf-static-wrap {
    background: #fff;
    min-height: calc(100vh - 80px);
  }

  .mcf-content-text {
    &, &::v-deep p {
      font-size: 18px;
      color: $static-color;
      line-height: 1.9;
      font-family: $main-font;
      font-weight: 400;
    }

    & {
      margin: 0 0 200px;
    }

    &::v-deep p {
      margin: 0 0 35px;
    }

    .mcf-static-small {
      font-size: 15px;
      font-weight: 500;
      color: #B3B7BA;
    }

    &::v-deep .mcf-static-small {
      font-size: 15px;
      font-weight: 500;
      color: #B3B7BA;
    }

    &::v-deep a:not(.mcf-btn) {
      color: $link-color;
      @include mq(tablet-wide, min) {
        &:hover {
          color: $link-hover-color;
        }
      }
    }

    &::v-deep h2,
    &::v-deep .mcf-content-h2 {
      font-family: $main-font;
      font-weight: 600;
      font-size: 20px;
      margin: 0 0 12px;
      line-height: 1.1;
    }

    ul {
      margin: 0 0 35px;
      padding: 0;

      li {
        margin: 0 0 28px;
        position: relative;
        list-style-type: none;
        padding: 0 0 0 20px;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 12px;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background: $link-color;
        }
      }
    }
  }

  .mcf-static-wrap {
    .mcf-flex-row {
      @include flexbox;
    }

    .mcf-flex-l {
      width: 100%;
      @include mq(tablet-wide, min) {
        width: 49%;
      }
    }

    .mcf-flex-r {
      display: none;
      width: 51%;
      padding: 0 0 0 6%;
      margin: -82px 0 0;
      text-align: right;

      img {
        width: 100%;
      }

      @include mq(tablet-wide, min) {
        display: block;
      }
    }

    .mcf-static-img-mob {
      display: block;
      max-width: 263px;
      margin: 0 auto 24px auto;
      @include mq(tablet-wide, min) {
        display: none;
      }
    }

    h1.mcf-static-title-mob {
      display: none;
    }
  }

  @include mq(tablet-wide, max) {
    .mcf-static-wrap {
      .mcf-tab-content {
        width: 100%;
        padding: 40px 24px 50px;

        h1 {
          font-size: 30px;
          font-weight: 600;
          text-align: center;
        }
      }

      .mcf-content-text {
        &, p {
          font-size: 14px;
          line-height: 2.29;
          margin: 0 0 20px;
        }

        ul li {
          font-size: 14px;
          line-height: 2.29;
          margin: 0 0 7px;
        }
      }

      .mcf-content-text::v-deep {
        p {
          font-size: 14px;
          line-height: 2.29;
          margin: 0 0 20px;
        }

        h2 {
          font-size: 16px;
          margin: 0 0 10px;
        }
      }

      &.mcf-about-static h1 {
        display: none;

        &.mcf-static-title-mob {
          display: block;
        }
      }
    }
  }
</style>
