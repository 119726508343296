import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'custom-event-polyfill'

import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import store from './vuex'
import Vuelidate from 'vuelidate'
import VueToastr from '@deveodk/vue-toastr'

Vue.config.productionTip = false
Vue.config.devtools = true

Vue.use(VueMeta, { refreshOnceOnNavigation: true })
Vue.use(Vuelidate)
Vue.use(VueToastr, {
  defaultPosition: 'toast-top-right',
  defaultType: 'info',
  defaultTimeout: 3000
})

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
