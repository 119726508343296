import { Model } from 'vue-mc'
import Type from './Type'
import Property from './Property'
import PropertyList from './PropertyList'
import { Ajax } from '@/util/ajaxLoader'
import store from '../../vuex'
import TagList from './TagList'
import Tag from './Tag'

export default class Obj extends Model {

  defaults () {
    return {
      id: null,
      typeId: null,
      completed: true,
      signed: false,
      isOwner: true,
      isNested: false
    }
  }

  mutations () {
    return {
      id: (id) => Number(id) || null,
      typeId: (typeId) => Number(typeId) || null,
      completed: (completed) => Boolean(completed),
      isOwner: (isOwner) => Boolean(isOwner),
    }
  }

  getType () {
    if (this.typeId) {
      let type = new Type({ id: this.typeId })
      return type.fetch().then(() => type)
    }
    return Promise.reject()
  }

  getProperties () {
    return Ajax.getInstance().sendGet(`${process.env.VUE_APP_HEALTHBANK_HOST}api/v0/property/object/${this.id}`).then(
      (response) => {
        if (!response.data) {
          return [new Property()]
        }
        return response.data.map((prop) => new Property(prop))
      }).catch((error) => {
      return [new Property()]
    })
  }

  getTags () {
    return Ajax.getInstance().sendGet(`${process.env.VUE_APP_HEALTHBANK_HOST}api/v0/tag/object/${this.id}`)
      .then((response) => {
        if (!response.data) {
          return []
        }
        return new TagList(response.data.map((tag) => new Tag(tag)))
      })
      .catch((error) => {
        return null
      })
  }

  async fetch (options = {}) {
    let defaultHeaders = await Ajax.getDefaultHeaders(store)
    options.headers = { ...options.headers, ...defaultHeaders }
    return super.fetch(options)
  }

  async getDisplaySettings (options = {}) {
    return Ajax.getInstance().sendGet(`${process.env.VUE_APP_HEALTHBANK_HOST}api/v0/typeDisplaySetting/object/${this.id}`).then((response) => {
      if (response.data == null) {
        return []
      }
      return response.data
    }).catch((error) => {
      console.error('Failed try to sendGet type display setting!', error)
      return []
    })
  }

  routes () {
    return {
      fetch: `${process.env.VUE_APP_HEALTHBANK_HOST}api/v0/object/{id}`,
    }
  }
}
