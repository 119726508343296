<template>
  <div class="mcf-objects-wrapper" :class="{'mcf-loading': !Boolean(objects)}">
    <div class="mcf-objects-body">
      <spinner :loading="!!loaderCount" :size="'35px'" :position="'absolute'"/>
      <div v-if="Boolean(objects) && objects.length">
        <div v-for="(object, i) in objects.models" :key="i">
          <panel-object-renderer
            v-if="Boolean(object)"
            :render-mode="renderMode"
            :object="object"
            :updateObjectsListEventBus="updateObjectsListEventBus"
            :with-additional-object-info="withAdditionalObjectInfo"
            @panel-object-header-init="checkPanelRender"
          />
        </div>
        <base-pagination
          v-if="!isNaN(pageCount)"
          :current-page="page"
          :size="size"
          :page-count="pageCount"
          @load-next-page="pageChangeHandle('next')"
          @load-previous-page="pageChangeHandle('previous')"
          @load-by-page="pageChangeHandle"
        />
      </div>
      <div v-else-if="Boolean(objects) && objects.length === 0">
        <panel-no-object-renderer/>
      </div>

    </div>
  </div>
</template>

<script>
import RenderMode from '../../components/patientData/RenderMode'
import ObjectList from '../../models/patientData/ObjectList'
import PanelObjectRenderer from '../../components/patientData/objects/PanelObjectRenderer'
import BasePagination from '../pagination/BasePagination'
import {mixinLoader} from '../../mixins/mixin-loader'
import PanelNoObjectRenderer from '../../components/patientData/objects/PanelNoObjectRenderer'

export default {
  name: 'PanelObjectList',
  components: {
    'panel-object-renderer': PanelObjectRenderer,
    'panel-no-object-renderer': PanelNoObjectRenderer,
    'base-pagination': BasePagination
  },
  props: {
    objects: ObjectList || null,
    page: {
      type: Number,
      required: false,
      default: 0
    },
    size: {
      type: Number,
      required: false,
      default: 10
    },
    pageCount: {
      type: Number,
      required: false,
      default: 0
    },
    updateObjectsListEventBus: {
      required: false,
    },
    withAdditionalObjectInfo: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  mixins: [mixinLoader],
  data() {
    return {
      renderMode: RenderMode.LIST_ITEM,
      currentPage: this.page,
      panelRenderCount: 0
    }
  },
  created() {
    if (this.updateObjectsListEventBus) {
      this.updateObjectsListEventBus.$on('update-objects-list', () => {
        this.showSpinner()
      })
    }

    this.showSpinner()
  },
  methods: {
    checkPanelRender() {
      this.panelRenderCount++
      if (this.panelRenderCount === this.objects.length) {
        this.hideSpinner()
        this.panelRenderCount = 0
      }
    },
    showSpinner() {
      this.$emit('loading-increment')
    },
    hideSpinner() {
      this.$emit('loading-decrement')
    },
    pageChangeHandle(value) {
      this.showSpinner()

      switch (value) {
        case 'next':
          this.currentPage += 1
          break
        case 'previous':
          this.currentPage -= 1
          break
        default:
          this.currentPage = value
          break
      }
      this.$emit('load-by-page', this.currentPage)
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/helpers/all-helpers";

.mcf-objects-body {
  position: relative;
  min-height: 160px;
}

</style>
