<template>
  <div class="mcf-form-column" :class="setPropertiesClass">
    <label class="mcf-label" v-show="showAttributeName">{{ item.attribute.name }}:</label>
    <div class="mcf-value-state">
      <input v-model="form.value" @input="updateValue"
             @focusin="dontShowValidationError"
             @focusout="showValidationError" class="mcf-input">
    </div>
    <validation-errors :$v="$v" :name="item.attribute.name" :isShowError="item.isShowError"/>
  </div>
</template>

<script>
import ObjectData from '../../ObjectData'
import { required } from 'vuelidate/lib/validators'
import ValidationErrors from './ValidationErrors'

export default {
  name: 'TextModify',
  components: {
    'validation-errors': ValidationErrors
  },
  data() {
    return {
      form: {
        value: this.property.value
      }
    }
  },
  props: {
    item: ObjectData,
    property: Object,
    autoFillData: Object,
  },
  validations () {
    return {
      form: {
        value: {
          required: this.item.attribute.currentDisplaySetting.required ? required : (d) => {
            return true
          }
          //required
        }
      }
    }
  },
  created () {
    this.setFormValue()
  },
  computed: {
    showAttributeName () {
      return !this.item.attribute.list
    },
    setPropertiesClass () {
      let className = ''
      if (this.$v.form.value.$invalid && this.item.isShowError) {
        className += ' mcf-error'
      }
      if (this.form.value.length) {
        className += ' mcf-fill'
      }
      return className
    },
  },
  methods: {
    setFormValue () {
      this.item.validator = this.$v
      this.item.isReadyToModify = true

      if (this.property.value == null) {
        if (this.autoFillData != null) {
          this.property.value = this.autoFillData.value
        } else {
          this.property.value = ''
        }
      }
      this.form.value = this.property.value
    },
    updateValue () {
      this.property.value = this.form.value
    },
    showValidationError () {
      this.item.isShowError = true
    },
    dontShowValidationError () {
      this.item.isShowError = false
    }
  }
}
</script>
