<template>
  <div v-if="isRender" class="mcf-input-wrapper mcf-datetime-wrapper">
    <label class="mcf-label" v-show="showAttributeName">{{ item.attribute.name }}:</label>
    <div class="mcf-input-datetime-wrapper">
      <input type="text" class="mcf-input" :placeholder="$t('field.day')" v-model="form.day" @input="updateValue"/>
      <input type="text" class="mcf-input" :placeholder="$t('field.month')" v-model="form.month" @input="updateValue"/>
      <input type="text" class="mcf-input" :placeholder="$t('field.year')" v-model="form.year" @input="updateValue"/>
    </div>
  </div>
</template>

<script>
import ObjectData from '../../ObjectData'
import DateFormatter from '../../../../util/date'
import Property from '../../../../models/patientData/Property'

const setForm = (value) => {
  return {
    year: DateFormatter.getFormatYear(value),
    month: DateFormatter.getFormatMonth(value),
    day: DateFormatter.getFormatDay(value)
  }
}

export default {
  name: 'DateCreate',
  props: {
    item: ObjectData,
    property: Object,
  },
  data () {
    return {
      isRender: false,
      form: {
        year: null,
        month: null,
        day: null
      }
    }
  },
  created () {
    this.prepareItemAndProperty()
  },
  computed: {
    showAttributeName () {
      return !this.item.attribute.list
    },
  },
  methods: {
    prepareItemAndProperty () {
      this.isRender = true
      this.item.isReadyToModify = true

      if (this.property.value == null) {
        this.form = setForm(new Date())
        this.property.value = DateFormatter.formatDate(new Date(Date.now()))
      }
    },
    updateValue () {
      let updatedDate = new Date(this.form.year, this.form.month - 1, this.form.day)
      this.property.value = updatedDate.getTime()
    },
  },
}
</script>
