<template>
  <div class="mcf-container">
    <div v-if="Boolean(objects)" class="mcf-content-wrapper">
      <spinner :loading="!!loaderCount"/>
      <div v-for="(object, i) in objects.models" v-show="!loaderCount" :key="i">
        <object-renderer v-if="Boolean(object)" :object="object" :render-mode="renderMode"
                         :updateObjectsListEventBus="updateObjectsListEventBus" v-on:is-rendered-object="hideSpinner"/>
      </div>
    </div>
  </div>
</template>

<script>
	import ObjectRenderer from '../../components/patientData/objects/ObjectRenderer'
	import RenderMode from '../../components/patientData/RenderMode'
	import ObjectList from '../../models/patientData/ObjectList'
	import {mixinLoader} from '../../mixins/mixin-loader'

	export default {
		name: 'ObjectList',
		components: {
			'object-renderer': ObjectRenderer
		},
		props: {
			updateObjectsListEventBus: null,
			objects: ObjectList || null,
		},
		mixins: [mixinLoader],
		data() {
			return {
				renderMode: RenderMode.LIST_ITEM
			}
		},
		async created() {
			this.objects.models.forEach(() => {
				this.$emit('loading-increment')
			})
		},
		methods: {
			hideSpinner() {
				this.$emit('loading-decrement')
			},
		},
	}
</script>

<style scoped>

</style>
