<template>
  <div v-if="isRender" class="mcf-input-wrapper mcf-datetime-wrapper">
    <label class="mcf-label" v-show="showAttributeName">{{ item.attribute.name }}:</label>
    <div class="mcf-input-datetime-wrapper">
      <input type="text" class="mcf-input" :placeholder="$t('field.year')" v-model="form.year" @input="updateValue"/>
      <input type="text" class="mcf-input" :placeholder="$t('field.month')" v-model="form.month" @input="updateValue"/>
      <input type="text" class="mcf-input" :placeholder="$t('field.day')" v-model="form.day" @input="updateValue"/>
      <div class="mcf-time-wrapper">
        <input type="text" class="mcf-input" :placeholder="$t('field.hours')" v-model="form.hours"
               @input="updateValue"/>
        <span class="mcf-separator">:</span>
        <input type="text" class="mcf-input" :placeholder="$t('field.minutes')" v-model="form.minutes"
               @input="updateValue"/>
      </div>
    </div>
  </div>
</template>

<script>
import ObjectData from '../../ObjectData'
import DateFormatter from '../../../../util/date'
import Property from '../../../../models/patientData/Property'

const setForm = (value) => {
  return {
    year: DateFormatter.getFormatYear(value),
    month: DateFormatter.getFormatMonth(value),
    day: DateFormatter.getFormatDay(value),
    hours: DateFormatter.getFormatHours(value),
    minutes: DateFormatter.getFormatMinutes(value)
  }
}

export default {
  name: 'DateTimeCreate',
  data () {
    return {
      isRender: false,
      form: {
        year: 0, // todo: discuss it
        month: 0,
        day: 0,
        hours: 0,
        minutes: 0
      }
    }
  },
  props: {
    item: ObjectData,
    property: Object,
  },
  created () {
    this.prepareItemAndProperty()
  },
  computed: {
    showAttributeName () {
      return !this.item.attribute.list
    },
  },
  methods: {
    prepareItemAndProperty () {
      this.isRender = true
      this.item.isReadyToModify = true

      if (this.property.value != null) {
        this.form = setForm(new Date(this.property.value))
      }
    },
    updateValue () {
      let updatedDate = new Date(this.form.year, this.form.month, this.form.day, this.form.hours, this.form.minutes)
      this.property.value = updatedDate.getTime()
    }
  }
}
</script>
