<template>
  <div id="preview-wrapper"></div>
</template>

<script>
  import pdfjsLib from "pdfjs-dist";
  import {Ajax} from "../../util/ajaxLoader";

  export default {
    name: "PdfPreview",

    props: {
      pdfUrl: null
    },
    async mounted() {
      const headers = await Ajax.getDefaultHeaders();

      const fileProps = {
        url: this.pdfUrl,
        httpHeaders: headers,
        withCredentials: true,
      };
      let pdf = await pdfjsLib.getDocument(fileProps);

      await this.renderPdf(pdf);

      this.$emit('is-rendered-object')
    },
    methods: {
      createNewPageCanvasWrapper(pageNumber) {
        let pageWrap = document.createElement("canvas");
        pageWrap.setAttribute("id", "canvas-wrapper-" + pageNumber);
        pageWrap.style.maxWidth = "100%";

        let previewWrap = document.getElementById('preview-wrapper');
        if (previewWrap) {
          previewWrap.appendChild(pageWrap);
        }

        return pageWrap;
      },
      prepareContext(page) {
        let scale = 1.5;
        let viewport = page.getViewport({scale: scale});

        let canvas = this.createNewPageCanvasWrapper(page.pageIndex);
        let context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        return {
          canvasContext: context,
          viewport: viewport
        };
      },
      renderPdfPage(page) {
        let renderContext = this.prepareContext(page);
        page.render(renderContext);
      },
      async renderPage(pdf, pageNumber) {
        let page = await pdf.getPage(pageNumber);
        this.renderPdfPage(page);
      },
      async renderPdf(pdf) {
        const numPages = pdf.numPages;

        for (let i = 1; i <= numPages; i++) {
          await this.renderPage(pdf, i);
        }
      }
    }
  }
</script>

<style scoped lang="scss">

</style>
