<template>
  <div class="mcf-content-text">
    <div v-html="userAgreement"></div>
  </div>
</template>

<script>
import {ContentDataApi} from "@/api/contentData";

export default {
		name: 'UserAgreement',
    data() {
      return {
        userAgreement: ''
      }
    },
		mounted() {
			window.scrollTo(0, 0)
		},
    async created() {
      this.userAgreement = await ContentDataApi.getInstance().fetchUserAgreement()
    }
	}
</script>

<style scoped>

</style>
