import { Model } from 'vue-mc'
import { Ajax } from '@/util/ajaxLoader'
import store from '../../vuex'

export default class EnumeratedItem extends Model {

  defaults () {
    return {
      id: null,
      enumeratedValuesId: null,
      name: null,
      value: null
    }
  }

  mutations () {
    return {
      id: (id) => Number(id) || null,
      enumeratedValuesId: (typeId) => Number(typeId) || null,
      name: (name) => String(name),
      value: (value) => String(value),
    }
  }

  async fetch (options = {}) {
    let defaultHeaders = await Ajax.getDefaultHeaders(store)
    options.headers = { ...options.headers, ...defaultHeaders }
    return super.fetch(options)
  }

  routes () {
    return {
      fetch: `${process.env.VUE_APP_HEALTHBANK_HOST}api/v0/enumeratedItem/{id}`,
    }
  }

}
