<template>
  <div class="mcf-form-row">
    <div class="mcf-label" v-html="item.attribute.name">
    </div>
  </div>
</template>

<script>
import ObjectData from '../../ObjectData'

export default {
  name: 'ImmutableTextCreate',
  props: {
    item: ObjectData,
    property: Object,
  },
  created () {
    this.item.isReadyToModify = true
  }
}
</script>
