<template>
  <div class="mcf-footer-wrap">
    <div class="mcf-container">
      <div class="mcf-footer-row">
        <div class="mcf-footer-col mcf-col-1">
          <router-link to="/" id="mcf-header-logo">
            <img src="@/assets/images/logo/logo-color@2x.png" class="mcf-footer-logo-img"/>
          </router-link>
          <ul>
            <li><router-link :to="localizeRouteLink('/content/about')">{{$t('footer.links.about')}}</router-link></li>
            <li><router-link :to="localizeRouteLink('/content/about/contacts')">{{$t('footer.links.impressum')}}</router-link></li>
          </ul>
        </div>
        <div class="mcf-footer-col mcf-col-2">
          <div class="mcf-footer-title">{{$t("footer.labels.doctor.title")}}</div>
          <ul>
            <li><router-link :to="localizeRouteLink('/content/information')">{{$t('footer.links.information')}}</router-link></li>
            <li><router-link :to="localizeRouteLink('/content/legal')">{{$t('footer.links.privacy')}}</router-link></li>
            <li><router-link :to="localizeRouteLink('/content/legal/userAgreement')">{{$t('footer.links.userAgreement')}}</router-link></li>
          </ul>
        </div>
        <div class="mcf-footer-col mcf-col-3">
          <div class="mcf-footer-title">{{$t("footer.labels.patient.title")}}</div>
          <ul>
            <li><router-link :to="localizeRouteLink('/content/legal')">{{$t('footer.links.privacy')}}</router-link></li>
            <li><router-link :to="localizeRouteLink('/content/legal/userAgreement')">{{$t('footer.links.userAgreement')}}</router-link></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	import UrlManager from "../../util/urlManager";

  export default {
		name: 'Footer',
		data: function () {
			return {
				solutionUrl: process.env.VUE_APP_SOLUTION_HOST
			}
		},
		methods: {
			localizeRouteLink: function (uri) {
        return UrlManager.composeLocaleUrl('', uri)
			}
		}
	}
</script>

<style lang="scss">
  @import "../../assets/style/helpers/all-helpers";

  .mcf-footer {
    &-wrap {
      padding: 40px 24px 0;
      background: #EDF1FE;
    }

    &-logo-img {
      height: 32px;
      width: auto;
      margin: 0 0 12px;
    }

    &-row {
      @include flexbox;
      @include flex-direction(column);
      width: 100%;
    }

    &-col {
      margin: 0 0 42px;

      a {
        font-family: $main-font;
        font-size: 14px;
        font-weight: 400;
        color: $text-color;
        line-height: 2;
        @include transition($main-transition);
      }
    }

    &-title {
      font-family: $main-font;
      font-size: 18px;
      font-weight: 600;
      color: $black;
      line-height: 1.52;
      margin: 0 0 8px;
    }

    @include mq(tablet-wide, min) {
      &-wrap {
        padding: 50px 0;
      }
      &-logo-img {
        margin: 0 0 6px;
      }
      &-row {
        @include flex-direction(row);
      }
      &-col {
        margin: 0;

        &.mcf-col-1 {
          @include flex-basis(26%);
        }

        &.mcf-col-2 {
          @include flex-basis(34%);
        }

        &.mcf-col-3 {
          @include flex-grow(1);
        }

        a {
          font-size: 16px;

          &:hover {
            color: $main-color;
          }
        }
      }
      &-title {
        font-size: 24px;
        margin: 0 0 12px;
      }
    }
  }
</style>
