import { Ajax } from '@/util/ajaxLoader'

const PROPERTIES_SAVE_PATH = 'api/v0/property/object'

const PROPERTIES_LIST_PATH = 'api/v0/property/list'

class Properties {
  constructor () {
    this.ajax = Ajax.getInstance()
  }

  batchPropertiesSave (objectId, body) {
    let url = PROPERTIES_SAVE_PATH
    if (objectId != null) {
      url = `${url}/${objectId}`
    }
    return this.ajax.sendPut(url, body, { 'Content-Type': 'application/json' })
  }

  list (propertyIds) {
    if (propertyIds != null && propertyIds.length !== 0) {
      let ids = propertyIds.join(',')
      return this.ajax.sendGet(PROPERTIES_LIST_PATH, {
        'ids': ids
      }, {
        'Content-Type': 'application/json'
      })
    }
  }
}

let properties = null

export const propertiesApi = {
  getInstance () {
    if (properties == null) {
      properties = new Properties()
    }
    return properties
  }
}
