<template>
  <div>
    <div class="mcf-tabs">
      <ul>
        <li v-for="(tab, i) in tabs" :key="i + 'T'" :class="{ 'mcf-active': tab.hash === tabHash }">
          <span v-if="tab.hash === tabHash">{{ tab.name }}</span>
          <a :href="tab.href" @click.prevent="selectTab(tab)" v-else>{{ tab.name }}</a>
        </li>
      </ul>
    </div>
    <div class="mcf-tabs-details">
      <slot></slot>
    </div>
  </div>
</template>

<script>
	export default {
		name: 'Tabs',
		props: ['tabEventBus'],
		data() {
			return {
				tabs: [],
				tabHash: null
			}
		},
		created() {
			this.tabEventBus.$on('set-tab-name', (tabHash) => {
				this.tabHash = tabHash
			})
			this.tabs = this.$children
		},
		methods: {
			selectTab(selectedTab) {
				this.$router.replace(selectedTab.link)
			}
		}
	}
</script>

<style lang="scss">
  @import "../../assets/style/helpers/all-helpers";

  .mcf-tab-content {
    padding: 92px 0 0;

    h1 {
      font-family: $main-font;
      font-weight: 700;
      font-size: 50px;
      color: $text-color;
      line-height: 1.35;
      margin: 0 0 29px;
    }

    .mcf-tabs > ul {
      @include inline-flex;
      @include align-items(flex-end);
      width: 100%;
      margin: 0 0 35px;
      border-bottom: 1px solid #BAEAFB;

      > li {
        margin: 0;
        padding: 0 15px 0 0;
        width: 33.33%;

        a, span {
          position: relative;
          display: inline-block;
          font-size: 18px;
          font-family: $main-font;
          font-weight: 500;
          color: $text-color;
          padding: 0 0 10px;

          &::before {
            @include pseudo();
            bottom: -2px;
            left: 0;
            height: 3px;
            width: 0;
            @include transition($main-transition);
            pointer-events: none;
          }
          @include mq(tablet-wide, min) {
            &:hover {
              &:before {
                background: $service-color;
                width: 110%;
              }
            }
          }
        }
        @include mq(tablet, min){
          padding: 0;
          a, span{
            font-size: 22px;
          }
        }

        &.mcf-active {
          a, span {
            color: $service-color;
            font-weight: 600;

            &:before {
              width: calc(100% + 15px);
              background: $service-color;
              @include mq(tablet, min){
                width: 110%;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .mcf-static-wrap {
      &.mcf-about-static {
        .mcf-tab-content {
          .mcf-tabs {
            > ul {
              @include justify-content(space-between);

              > li {
                width: auto;
              }
            }
          }
        }
      }
      &.mcf-static-license{
        .mcf-tab-content {
          .mcf-tabs {
            > ul {

              > li {
                width: 50%;
              }
            }
          }
        }
      }
    }
  }
</style>
