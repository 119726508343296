import {Ajax} from '@/util/ajaxLoader'

class FileDownloader {
  constructor() {
    this.ajax = Ajax.getInstance()
  }

  async fetchBlobFile(url, params, headers) {
    const downloadedFile = await this.ajax.sendGet(url, params || {}, headers || {}, {responseType: 'blob'})

    return new Blob([downloadedFile.data], {type: 'application/octet-stream'})
  }

  async fetchFileInBase64(url, fileName, params, headers) {
    const blob = await this.fetchBlobFile(url, params, headers);
    return await this.blobToBase64(blob)
  }

  async blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  async downloadFile(url, fileName, params, headers) {
    const blob = await this.fetchBlobFile(url, params, headers);
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }
}

export const fileDownloader = new FileDownloader();
