import { Collection } from 'vue-mc'
import Property from './Property'

export default class PropertyList extends Collection {
  options () {
    return {
      model: Property
    }
  }
}
