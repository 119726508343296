<template>
  <div class="mcf-content-text">
    <div v-html="privacyPolicy"></div>
  </div>
</template>

<script>
  import {ContentDataApi} from "@/api/contentData";

	export default {
		name: 'Legal',
    data() {
      return {
        privacyPolicy: ''
      }
    },
	  mounted() {
		  window.scrollTo(0, 0)
	  },
    async created() {
      this.privacyPolicy = await ContentDataApi.getInstance().fetchPrivacyPolicy()
    },
	}
</script>

<style scoped>
ul{
  list-style: disc;
  margin: 0 0 35px;
  padding: 0 0 0 40px;
}
</style>
