<template>
  <div>
    <div v-for="item in renderData">
      <div v-show="isShowItem(item)">
        <div class="mcf-list-wrap" v-if="allowShowAsList(item)">
          <div class="mcf-label">{{ item.attribute.name }}</div>
          <div class="mcf-list-row" v-for="property in item.properties" :key="property.index">
            <component :is="computePropertyTag(item)" :item="item" :property="property"/>
          </div>
        </div>
        <div v-else>
          <component :is="computePropertyTag(item)" :item="item" :property="item.properties[0]"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextShow from './show/TextShow'
import BooleanShow from './show/BooleanShow'
import NotNullBooleanShow from './show/NotNullBooleanShow'
import LinkShow from './show/LinkShow'
import NumericShow from './show/NumericShow'
import DateShow from './show/DateShow'
import DateTimeShow from './show/DateTimeShow'
import UploadedShow from './show/UploadedShow'
import AttributeType from '../AttributeTypes'
import ImmutableTextShow from './show/ImmutableTextShow'
import LongTextShow from './show/LongTextShow'
import EnumShow from './show/EnumShow'
  import NestedObjectShow from "./show/NestedObjectShow";

  export default {
    name: 'PropertiesShowRenderer',
    components: {
      'enum-show': EnumShow,
      'text-show': TextShow,
      'long-text-show': LongTextShow,
      'boolean-show': BooleanShow,
      'not-null-boolean-show': NotNullBooleanShow,
      'link-show': LinkShow,
      'numeric-show': NumericShow,
      'date-show': DateShow,
      'date-time-show': DateTimeShow,
      'immutable-text-show': ImmutableTextShow,
      'uploaded-show': UploadedShow,
      'nested-object-show': NestedObjectShow
    },
    props: {
      renderData: Array
    },
    methods: {
    allowShowAsList (item) {
      let attributeType = item.attribute.attributeType
      return item.attribute.list
          && attributeType !== AttributeType.BOOLEAN
          && attributeType !== AttributeType.NOT_NULL_BOOLEAN
          && attributeType !== AttributeType.IMMUTABLE_TEXT
          && attributeType !== AttributeType.UPLOADED
          && attributeType !== AttributeType.NESTED_OBJECT
    },
    computePropertyTag (item) {
      return AttributeType.resolveTypePrefix(item.attribute.attributeType) + '-show'
    },
    isShowItem (item) {
      return item.attribute.currentDisplaySetting.showInForm
    },
  }
}
</script>
