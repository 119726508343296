import Vue from 'vue'
import Router from 'vue-router'
import AllContent from './components/AllContent.vue'
import i18n, {ALL_LOCALES} from './i18n'
import Home from './views/Home'
import store from './vuex'
import ShowMyObjects from './views/ShowMyObjects'
import ObjectEdit from './views/object/ObjectEdit'
import ObjectShow from './views/object/ObjectShow'
import ObjectCreate from './views/object/ObjectCreate'
import ShowPermittedObjects from './views/ShowPermittedObjects'
import ShowThreadedObjects from './views/ShowThreadedObjects'
import NotFoundPage from './views/error/NotFoundPage'
import About from './components/static/about/About'
import Information from './components/static/Information'
import Legal from './components/static/license/legal/Legal'
import UserAgreement from './components/static/license/userAgreement/UserAgreement'
import Manifesto from './components/static/about/Manifesto'
import Contacts from './components/static/about/Contacts'
import Impressum from './components/static/about/Impressum'
import License from './components/static/license/License'

Vue.use(Router)

let locale = i18n.locale
init()

const urls = [
  {
    path: 'content/about',
    component: About,
    props: true,
    children: [
      {
        path: "",
        component: Manifesto
      },
      {
        path: "impressum",
        component: Impressum
      },
      {
        path: "contacts",
        component: Contacts
      }
    ]
  },
  {
    path: 'content/information',
    component: Information
  },
  {
    path: 'content/legal',
    component: License,
    props: true,
    children: [
      {
        path: "",
        component: Legal
      },
      {
        path: "userAgreement",
        component: UserAgreement
      }
    ]
  },
  {
    path: 'show-my-objects',
    component: ShowMyObjects
  },
  {
    path: 'thread/:threadId',
    component: ShowThreadedObjects
  },
  {
    path: 'show-permitted-objects',
    component: ShowPermittedObjects,
  },
  {
    path: 'object/edit/:id',
    component: ObjectEdit,
  },
  {
    path: 'object/show/:id',
    component: ObjectShow,
  },
  {
    path: 'object/create/:typeId',
    component: ObjectCreate
  },
]

export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: '/',
      component: Home
    },
    {
      path: '/(fr|en|it)/',
      component: Home
    },
    {
      path: '/(fr|en|it)',
      component: AllContent,
      children: urls
    },
    {
      path: '/',
      component: AllContent,
      children: urls
    },
    {
      path: '/404',
      name: '404',
      component: NotFoundPage
    },
    {
      path: '*',
      component: NotFoundPage
    }
  ]
})

function init() {
  locale = window.location.pathname.replace(/^\/([^\/]+).*/i, '$1')

  if (ALL_LOCALES.indexOf(locale) === -1) {
    locale = process.env.VUE_APP_I18N_DEFAULT_LOCALE
  }
  store.commit('setLocale', locale)
  return locale
}

router.beforeEach((to, from, next) => {
  let urlLocale = to.path.replace(/^\/([^\/]+).*/i, '$1')

  if (ALL_LOCALES.indexOf(urlLocale) === -1) {
    urlLocale = process.env.VUE_APP_I18N_DEFAULT_LOCALE
  }

  if (store.state.locale !== urlLocale) {
    let newUrl = addLocalePrefixForUrl(to.fullPath, store.state.locale, urlLocale)
    router.push({path: newUrl}).catch(err => {
      if (err.name === 'NavigationDuplicated') {
        console.log('NavigationDuplicated ignored') //todo resolve rerender || tmp ignore
      }
    })
    return
  }
  next()
})

router.changeLocale = (newLocale) => {
  let url = window.location.pathname + window.location.search
  let currentUrlLocale = url.replace(/^\/([^\/]+).*/i, '$1')

  if (ALL_LOCALES.indexOf(currentUrlLocale) === -1) {
    currentUrlLocale = process.env.VUE_APP_I18N_DEFAULT_LOCALE
  }

  if (newLocale !== currentUrlLocale) {
    let newUrl = addLocalePrefixForUrl(url, newLocale, currentUrlLocale)
    router.push({path: newUrl})
  }
  window.location.reload()
}

function addLocalePrefixForUrl(url, newLocale, currentUrlLocale) {
  let newUrl = ''
  if (newLocale === process.env.VUE_APP_I18N_DEFAULT_LOCALE) {
    newUrl = url.replace(`/${currentUrlLocale}`, '')
  } else if (currentUrlLocale === process.env.VUE_APP_I18N_DEFAULT_LOCALE) {
    newUrl = `/${newLocale}${url}`
  } else {
    newUrl = url.replace(`/${currentUrlLocale}`, `/${newLocale}`)
  }

  if (!newUrl) {
    return '/'
  }
  return newUrl
}

export default router
