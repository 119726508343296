<template>
  <div class="mcf-input-wrapper mcf-checkbox-wrapper" v-show="item.isReadyToModify"
       :class="{'mcf-error': $v.form.value.$invalid && item.isShowError}">
    <input type="checkbox" v-model="form.value" :id="getCheckboxId"
           @focusin="dontShowValidationError"
           @focusout="showValidationError"
           class="mcf-input" @input="updateValue"/>
    <label :for="getCheckboxId" class="mcf-label mcf-label-checkbox">
      {{ item.attribute.name }}
      <em class="mcf-icon mcf-icon-check"></em>
    </label>
    <validation-errors :$v="$v" :name="item.attribute.name" :isShowError="item.isShowError"/>
  </div>
</template>

<script>
import ObjectData from '../../ObjectData'
import ValidationErrors from './ValidationErrors'
import { isTrue } from '../validators/CustomValidators'

export default {
  name: 'BooleanModify',
  components: {
    'validation-errors': ValidationErrors
  },
  props: {
    item: ObjectData,
    property: Object,
    autoFillData: Object,
  },
  data () {
    return {
      form: {
        value: this.property.value
      }
    }
  },
  validations () {
    let value = {}
    if (this.item.attribute.currentDisplaySetting.required) {
      value.isTrue = isTrue()
    }
    return {
      form: {
        value: value
      }
    }
  },
  created () {
    this.setFormValue()
  },
  computed: {
    getCheckboxId () {
      return `${this.item.attribute.attributeType}-${this.item.attribute.id}`
    },
  },
  methods: {
    updateValue () {
      this.form.value = !this.form.value
      this.property.value = this.form.value
    },
    showValidationError () {
      this.item.isShowError = true
    },
    dontShowValidationError () {
      this.item.isShowError = false
    },
    setFormValue () {
      this.item.validator = this.$v
      this.item.isReadyToModify = true

      if (this.property.value == null) {
        if (this.autoFillData != null) {
          this.property.value = this.autoFillData.value
        } else {
          this.property.value = false
        }
      }
      this.form.value = this.property.value
    }
  }
}
</script>
