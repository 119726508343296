export default class DateFormatter {

  static formatDate (date) {
    let year = date.getFullYear()
    let month = DateFormatter.toTwoDigits(date.getMonth() + 1)
    let day = DateFormatter.toTwoDigits(date.getDate())
    let time = DateFormatter.getFormatTime(date)

    return year + '-' + month + '-' + day + 'T' + time
  }

  static formatShowDate (date) {
    let year = date.getFullYear()
    let month = DateFormatter.toTwoDigits(date.getMonth() + 1)
    let day = DateFormatter.toTwoDigits(date.getDate())

    return day + '.' + month + '.' + year
  }

  static formatShowDateTime (date) {
    let hours = DateFormatter.toTwoDigits(date.getHours())
    let minutes = DateFormatter.toTwoDigits(date.getMinutes())
    let seconds = DateFormatter.toTwoDigits(date.getSeconds())

    return this.formatShowDate(date) + ' ' + hours + ':' + minutes + ':' + seconds
  }

  static getFormatYear (date) {
    return date.getFullYear()
  }

  static getFormatMonth (date) {
    return DateFormatter.toTwoDigits(date.getMonth() + 1)
  }

  static getFormatDay (date) {
    return DateFormatter.toTwoDigits(date.getDate())
  }

  static getFormatHours (date) {
    return DateFormatter.toTwoDigits(date.getHours())
  }

  static getFormatMinutes (date) {
    return DateFormatter.toTwoDigits(date.getMinutes())
  }

  static formatInputDate (date) {
    let year = date.getFullYear()
    let month = DateFormatter.toTwoDigits(date.getMonth() + 1)
    let day = DateFormatter.toTwoDigits(date.getDate())
    return year + '-' + month + '-' + day
  }

  static getFormatTime (date) {
    let hour = DateFormatter.toTwoDigits(date.getHours())
    let minute = DateFormatter.toTwoDigits(date.getMinutes())
    return hour + ':' + minute
  }

  static toTwoDigits (digit) {
    if (digit < 10) {
      return '0' + digit
    }
    return digit
  }

}

